

import React from 'react';
import Navbars from '../../MinutesPage/Navbars'
import {Container, Navbar, Col, Row } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';


function Sept2023Meeting() {
  return (
    <div className="Article-cover">
    {<Navbars/>}

   {/*    page body starts here */}

   <main className='main'>
     
   <h2> Minutes for September</h2>
   <p style={{fontSize:'1.1rem'}}>
   <b>Date:</b> 10/09/2023
   <br/>
   <strong>Members Present:</strong> <span className='m-present'>.
   Kingsley Agoha, Francis, Charles Obodoechina, Emeka Onwuzo, Chijioke, Austin, Joseph and Innocent Ike, Soki
   </span>
   <br/><br/>
   <strong>Members Absent:</strong> <span className='m-abscent'>
   Chidi Usanga,  Chidebere, Ugo Nwankwo, Ike Nwadike, Edozie, Peter and Kingsley Iqwe
   </span>
   <strong>Vnisitors/New members:</strong> <span className='m-abscent'>
    Udemezue and Chigozie Ebele-Nna-Mbu
   </span>
   </p>

<h5>Topics Discussed</h5>


<p className='text-dark'>
•	Previous Meeting Review/observation<br/>
•	Dublin Trip Review<br/>
•	Christmas Party <br/>
•	 ICANWI Transition<br/>
•	 Bank and Cash Balances<br/>
•	 Ay Other Business<br/>
•	Finance<br/>

</p>



   <h5>Previous Meeting Review/observation</h5>
   <p>
   
   The meeting started at 5 PM. Udemezuo led an opening prayer, after which Francis read the minutes of the previous meeting. An observation was made by Chuks - that a trip to Dublin was discussed but not recorded. This was subsequently corrected, and the minutes were approved by Chuks. The chairman in his opening remarks, welcomed all the members present, and in the spirit of hope and optimism - envisioned that in due time, our group would have a permanent meeting place that we could call our own, putting an end to the practice of hosting meetings in members' houses. <br/><br/>
   A new member introduced himself as Chigozie Ebele from Awka in Anambra State. He was welcomed by all, and his decision to join the group was also encouraged by everyone present. In the same vein, the chairman and Francis encouraged Jude to officially register with the group, irrespective of whether his program in Derry would be temporary or not. In response, Jude revealed that he would have registered earlier, but the fear of relocation had been holding him back. However, he agreed to register in order to commit himself to the goals of the association for the duration of his stay in Derry


  </p>


   <h5>	Dublin Trip Review</h5>
   <p>
   The chairman told the group that our trip to Dublin was a success, and our participation was highly appreciated by the host. He subsequently played a video clip of the event for the members. In addition, he presented some bottles of Guinness and a hot drink, along with a cash gift that the group brought back from Dublin. He also provided a record of the expenses incurred. Chuks thanked the group for their commitment and performance, emphasizing that the association was well represented.

  </p>


  <h5>Annual Christmas Party :</h5> 
  <p>
  The chairman updated the group on the actions he has taken and the progress made in securing a party venue in Derry. He assured the members that the property management has agreed to his chosen date, which is Saturday, December 9, 2023. However, some members expressed concerns about this date, as it may not be convenient for everyone due to work schedules. They suggested that Sunday, December 20th, would be an ideal alternative date if it's available.
Innocent mentioned that the venue is the best fit for the occasion, but he pointed out that the price is somewhat higher compared to previous venues. After reviewing the amounts paid for previous venues, the group discovered that the price difference was not significant enough to rule out the Derry venue.

  </p>
  <p>
  Regarding preparation, Francis expressed concern about the low level of commitment and engagement, emphasizing their negative impact on the progress of last year's Christmas party. Innocent stressed the importance of addressing issues related to proper planning and commitment, recommending the delegation of responsibilities instead of relying on just a few individuals. He also suggested incorporating attractive activities such as couple dances and the tying of head scarves between husbands and wives. 
  </p>
  <p>
  Francis noted that while these activities had been implemented before, they required significant planning, commitment, and resources. The chairman encouraged members living near the party venue to demonstrate greater commitment to enhance engagement and avoid any potential embarrassment. Furthermore, Innocent stressed the significance of engaging a skilled DJ to guarantee a memorable event.

It was collectively agreed that the association would seek out a skilled DJ who could be responsive to the event's needs, support the group's social objectives, and promote Igbo culture effectively

  </p>

  <h5>ICANWI Transition:</h5>
  <p>
  The chairman briefly explained the association's origins, starting as the Igbo Association Donegal because members from the Republic of Ireland outnumbered those from the northern region. As the northern membership grew, the association's name was changed to Igbo Cultural Association North-West to be more inclusive. However, the association has faced challenges in receiving government support due to registration issues and the jurisdiction under which it operates.<br/><br/> He emphasized that it is now time for the group to carefully consider under which jurisdiction they should register. Innocent stressed the urgency of registration but expressed uncertainty about the best jurisdiction to choose. Soki suggested registering under the jurisdiction that would provide better and faster funding opportunities, with the majority favoring Derry in terms of funding prospects. Chucks cautioned against jumping to conclusions without proper investigations. 
He recommended conducting comprehensive research, with a particular focus on understanding the critical requirements and benefits before arriving at any decisions. Jude proposed the formation of a five-men's committee to oversee the research and registration process. The chairman pledged to make inquiries and provide further information on the meeting
  </p>

  <h5>	Bank and Cash Balances /Operation:</h5>
  <p>
  On behalf of the accounting committee, the chairman apologized for the delay in the account reconciliation and the submission of the report. The delay was due to members' tight schedules. He assured the house that the issue would be resolved before the next meeting.
  </p>
  <h5>	Code of Conduct/Members Details:</h5>
  <p>

  The chairman emphasized the importance of adhering to our code of conduct within the context of group activities and programs, maintaining  that it should be published on our website, along with members' details. He also stressed the mandatory nature of our official uniform, encouraging members to wear it occasionally to maintain a sense of cultural / group uniformity. An observer advised against the shared use of the event uniform among the members. He suggested that Members should purchase and possess it at a reasonable price of €10.
 Francis found the idea strategic, as it would assist the group in recuperating the expenses associated with producing the uniforms. Ogidi suggested an exception, proposing that the team that represented the group at the Dublin event should receive the uniform free of charge. It was decided that all members should contribute €10 to acquire the uniform.






  </p>

  <h5>Any Other Business</h5>
  <p>
  Joseph Opara brought a bottle of a hot drink as a token of gratitude for the group's support and encouragement during his challenging times. He also used that occasion to announce to the group that he had secured a new job. Members expressed their gratitude, thanking him for sharing such great news and offering prayers for even more blessings in his life.
  </p>

   <h5>Finance</h5>
   <p>
   A total of €110 was collected and handed over to the treasurer. Chuks move for Adjournment.  The next meeting was scheduled for the 8th of Oct 2023 at the Innocent Ike’s house in Derry. The closing prayer was led by Francis.
  </p>

   <Container>
         <Row className='d-flex justify-content-center align-items-center'>
           <strong><h4 className='text-danger p-2 px-0 sumary'>
             Summary of the Dues</h4>
           </strong>
           </Row>

           <div className='tab' style={{minWidth:'400px',maxWidth:'400px'}}>
   
         <Table striped bordered hover variant="dark">
         <thead>
           <tr>
           <th>NAMES</th>
           <th>DEUS</th>
           
      
           </tr>
         </thead>

         <tbody>
         <tr>
           <td>Emeka</td>
           <td>€10 </td>
        </tr>
         <tr>
           <td>Soki</td>
           <td>€20</td>
        </tr>
         
         <tr>
           <td>Joseph</td>
           <td>€10 </td>
        </tr>
        <tr>
           <td>Ugo</td>
           <td>not paid</td>
        </tr>
         <tr>
           <td>Austin</td>
           <td>€10 </td>
        </tr>
         <tr>
           <td>Chuks</td>
           <td>€10 </td>
        </tr>
         <tr>
           <td>Kingsley</td>
           <td>€10 </td>
        </tr>
      
        
         <tr>
           <td>Francis</td>
           <td>€10 </td>
        </tr>

         <tr>
           <td>Charley</td>
           <td>€20 - Sept & Oct </td>
        </tr>
         <tr>
           <td>Emmanuel</td>
           <td>not paid </td>
        </tr>
         <tr>
           <td>Kingsley Iqwe</td>
           <td>not-paid</td>
        </tr>
         <tr>
           <td>Innocent Ike</td>
           <td>not paid</td>
        </tr>
         <tr>
           <td>Peter</td>
           <td>Not Paid</td>
        </tr>
        </tbody>
         </Table> 
         </div>
       </Container>

 </main>
</div>
);
}

export default Sept2023Meeting;


         


           
              
      