
import React, {useState, useEffect} from 'react';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import  './CSS/App.scss';
import  './CSS/Video.scss';
import  './CSS/Constitution.scss';
import  './CSS/custom.scss';
import  './CSS/Minutes.scss';
import  './CSS/MinutesPages.scss';
import './CSS/Mediaquery.scss';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import styled from 'styled-components';
import {BrowserRouter as Router,Routes, Route} from "react-router-dom";
import SignIn from './components/Header/DropdownContainer/SignIn';
import { AuthProvider } from './Auth';
import RotaTable from './components/MinutesPage/RotaTable';
import Finance  from './components/Pages/InnerPage/Finance';
import BlogPages from './components/Blogs/BlogPages';
import RequireAuth from './components/ReguireAuth'
import Navbars from './components/MinutesPage/Navbars';
import May2022meeting from './components/Pages/InnerPage/May2022Meeting';
import May2023Meeting from './components/Pages/InnerPage/May2023Meeting';
import June2023Meeting from './components/Pages/InnerPage/JuneMeeting2023';
import August2023Meeting from './components/Pages/InnerPage/August2023Meeting';
import Sept2023Meeting from './components/Pages/InnerPage/Sept2023Meeting';
import April2022Meeting from './components/Pages/InnerPage/April2022Meeting';
import Feb2023Meeting from './components/Pages/InnerPage/Feb2023Meeting';
import Feb2022Meeting from './components/Pages/InnerPage/Feb2022Meeting';
import March2023Meeting from './components/Pages/InnerPage/March2023Meeting';
import April2023Meeting from './components/Pages/InnerPage/April2023Meeting';
import March2022Meeting from './components/Pages/InnerPage/March2022Meeting';
import June2022Meeting from './components/Pages/InnerPage/June2022Meeting'; 
import Sept2022Meeting from './components/Pages/InnerPage/Sept2022Meeting';
import Oct2019Meeting from './components/Pages/InnerPage/Oct2019Meeting';
import Oct2022Meeting from './components/Pages/InnerPage/Oct2022Meeting';
import Nov2022Meeting from './components/Pages/InnerPage/Nov2022Meeting';

import Constitution from './components/Pages/InnerPage/Constitution'; 
import UpdatePage from './components/Pages/UpdatePage';
import Gallary from './components/Pages/Gallary';
import PrivatePage from  './components/MinutesPage/PrivatePage'
import ResetPassword from './components/Header/ResetPassword';
import Videos from './components/Pages/VideosGallery';
import Charity from './components/Body/HomePage/Whatwedo/Charity';
import Equality from './components/Body/HomePage/Whatwedo/Equality';
import Integration from './components/Body/HomePage/Whatwedo/Integration';
import Engagment from './components/Body/HomePage/Whatwedo/Engagment';
import Acheivement from './components/Body/HomePage/Whatwedo/Acheivement';
import Menu from './components/Header/DropdownContainer/Menu'
import FoodDetails from './components/Body/FoodDetails';
import LanguageDetail from './components/Body/HomePage/Whatwedo/LanguageDetail';
import Nkwobi from './components/FoodDetails/Nkwobi';
import Portfolio from './components/Portfolio';
const  Profile = React.lazy(() => import('./components/Body/HomePage/Profile')) ;
const FoodList = React.lazy(() => import('./components/Body/HomePage/FoodList')) ;
const Home = React.lazy(() => import('./components/Home')); 
const analyticKey= process.env.REACT_APP_API_KEY;


const App =() =>{

/*   
useEffect(()=>{
alert("Hello NdI Nkem!!")
},[]) */

const Layout=()=>{
  return(
    <Outlet/> )}
const Layout2=()=>{
  return(
    <Outlet/> )}

    
return (
<Router>
<AuthProvider>
<APP className='p-0 m-0 text-light h-100'>
<AppHeader>

<Routes>
<Route path='/' element={<Home/>}/>
<Route path='/Nkwobi' element={ <Nkwobi/>}/>
<Route path='/Portfolio' element={ <Portfolio/>}/>
<Route path='/FoodDetails' element={ <FoodDetails/>}/>
<Route path='/Menu' element={ <Menu/>}/>
<Route path='/LanguageDetail' element={ <LanguageDetail/>}/>
<Route path='/Profile' element={ <Profile/>}/>
<Route path='/Gallary' element={ <Gallary/>}/>
<Route path='/Acheivement' element={ <Acheivement/>}/>
<Route path="/NativeFood/:id" element={ <FoodList/> }/>
<Route path='/PrivatePage' element={ <PrivatePage/>}/>
<Route path='/SignIn' element={<SignIn/>}/>
<Route path='/ResetPassword' element={<ResetPassword/>}/>
<Route path='/BlogPages' element={ <BlogPages/>}/>
<Route path='/UpdatePage' element={ <UpdatePage/>}/>
<Route path='/Videos' element={ <Videos/>}/>
<Route path='/Activities' element={ <Layout2/>}>
<Route path='Charity' element={ <Charity/>}/>
<Route path='Equality' element={ <Equality/>}/>
<Route path='Integration' element={ <Integration/>}/>
<Route path='Engagment' element={ <Engagment/>}/></Route>
<Route path='/PrivatePage' element={
<RequireAuth> 
<Layout/>
</RequireAuth>  
}>
<Route path='RotaTable' element={<RotaTable/>}/>
<Route path='Feb2023Meeting' element={<Feb2023Meeting/>}/>
<Route path='March2023Meeting' element={<March2023Meeting/>}/>
<Route path='April2023Meeting' element={<April2023Meeting/>}/>
<Route path='May2023Meeting' element={<May2023Meeting/>}/>
<Route path='June2023Meeting' element={<June2023Meeting/>}/>
<Route path='August2023Meeting' element={<August2023Meeting/>}/>
<Route path='Sept2023Meeting' element={<Sept2023Meeting/>}/>
<Route path='Feb2022Meeting' element={<Feb2022Meeting/>}/>
<Route path='March2022Meeting' element={<March2022Meeting/>}/>
<Route path='April2022Meeting' element={<April2022Meeting/>}/>
<Route path='May2022Meeting' element={<May2022meeting/>}/>
<Route path='June2022Meeting' element={<June2022Meeting/>}/>
<Route path='Sept2022Meeting' element={<Sept2022Meeting/>}/>
<Route path='Oct2022Meeting' element={<Oct2022Meeting/>}/>
<Route path='Nov2022Meeting' element={<Nov2022Meeting/>}/>
<Route path='Oct2019Meeting' element={<Oct2019Meeting/>}/>
<Route path='Constitution' element={<Constitution/>}/>
<Route path='Finance' element={<Finance/>}/>
</Route> 
</Routes>
</AppHeader>
</APP> 
</AuthProvider>
</Router>
 
);


}

export default App;

const APP = styled.div`
position: relative;
min-width:100vw;
min-height:100vh;
height:auto;
overflow-y:scroll;
overflow-x:hidden;
box-sizing:border-box;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
padding:0;
margin: 0 auto;
`
const AppHeader = styled.div`
position:absolute;
left:0;
right:0;
bottom:0;
top:0;
margin:0;
padding:0;
top:0;
width:100% !important;
height:100%  !important;

`

