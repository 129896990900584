import React from 'react';
/* import './Portfolio.css'; // Make sure to create this CSS file for styling
 */import profileImage from '../components/IMAGES/frayo.jpeg'; // Import your profile image
import bookFinderImage from '../components/IMAGES/book.jpg'
import africanFoodImage from '../components/IMAGES/sandy.jpg';
import igboCultureImage from '../components/IMAGES/igbos.png';
import styled from 'styled-components';

const Portfolio = () => {
  return (
    <PORTFOLIO className="portfolio-container">
      <header>
        <img src={profileImage} alt="Your Name" className="profile-image" />
        <h1>Your Name</h1>
        <p>Web Developer | Computer Science & Business Admin Graduate</p>
      </header>
      <section className="about">
        <h2>About Me</h2>
        <p>
          I am a passionate web developer with expertise in Node.js, React, CSS, and HTML. With a background in both computer science and business administration, I bring a unique blend of technical skills and strategic thinking to my projects.
        </p>
      </section>
      <section className="experience">
        <h2>Experience</h2>
        <p>2 years of experience in content creation</p>
      </section>
      <section className="projects">
        <h2>Projects</h2>
        <div className="project">
          <h3>Adhoc Book Finder</h3>
          <img src={bookFinderImage} alt="Adhoc Book Finder" className="project-image" />
          <p>A web application that allows users to search and discover books based on their interests.</p>
        </div>
        <div className="project">
          <h3>SandysAfricanFoodHub.com</h3>
          <img src={africanFoodImage} alt="Sandys African Food Hub" className="project-image" />
          <p>An online platform showcasing African food recipes and cultural stories.</p>
        </div>
        <div className="project">
          <h3>Igbo Cultural Association</h3>
          <img src={igboCultureImage} alt="Igbo Cultural Association" className="project-image" />
          <p>A website dedicated to preserving and promoting Igbo cultural heritage.</p>
        </div>
      </section>
    </PORTFOLIO>
  );
};

export default Portfolio;
const PORTFOLIO = styled.div`

/* Portfolio.css */

  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;


header {
  text-align: center;
  margin-bottom: 30px;
}

h1 {
  font-size: 36px;
  margin: 0;
}

p {
  font-size: 18px;
  margin: 10px 0;
}

.about,
.experience,
.projects {
  margin-bottom: 40px;
}

.project {
  border: 1px solid #ccc;
  padding: 15px;
  margin-bottom: 15px;
}

h2 {
  font-size: 24px;
  margin: 0;
}

h3 {
  font-size: 20px;
  margin: 0;
}
/* Portfolio.css */
/* ... previous styles ... */

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 0 auto 20px;
  display: block;
}

.project-image {
  max-width: 100%;
  height: auto;
  margin: 10px 0;
}


@media (max-width: 768px) {
  h1 {
    font-size: 28px;
  }

  p {
    font-size: 16px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 18px;
  }
}




`