




import React from 'react';
import Navbars from '../../MinutesPage/Navbars'
import {Container, Navbar, Col, Row } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';


function May2022meeting() {
  return (
    <div className="Article-cover">
    {<Navbars/>}

   {/*    page body starts here */}

   <main className='main'>
     
   <h2> Minutes for May</h2>
   <p style={{fontSize:'1.1rem'}}>
   <b>Date:</b> 8/05/2022
   <br/><br/>
   <strong>Members Present:</strong> <span className='m-present'>.
   Kingsley, Obodoechina, Francis, Emeka, Ugo, Billy
   </span>
   <br/><br/>
   <strong>Members Absent:</strong> <span className='m-abscent'>
   CJ, obinna, Chuks and Eloka
   </span>
   </p>
   <h5>Proceedings</h5>
   <p>
   The Meeting was called to order at 5:00 PM. Open prayer was led by Kingsley. The minutes of the last meeting were read by Francis. It was approved by the chairman and seconded by Ugo. The primary topic of discussion was membership retention, and Francis recorded the minutes. the chairman expressed gratitude to all members in attendance and inquired about the absentees.
  </p>

  <p>
  Regarding the matter of membership retention, the chairman expressed concerns about members such as CJ and Eloka who have neglected to follow the simple protocol of attending meetings or requesting excuses, even when there seems to be no reasonable explanation for their absence, and the association is unaware of any valid excuse. Due to the sensitive nature of the issue, the chairman advised caution in dealing with it, as mishandling it could result in confusion.
    </p>
  <p>
  Francis and Kingsley believed that it was necessary to adhere to the provisions of the constitution regarding members who have defaulted from the association. This means that such members would no longer be considered constitutional members until they complete the necessary requirements outlined in the constitution to reactivate their membership
  </p> 
  <p>
  Billy emphasized the importance of growing the association's membership, as members are essential for any organization's survival. He expressed concern about the members leaving the group and warned that this could affect the association's ability to fulfill its mission effectively. To address this, he urged the association to provide inclusive leadership and added value to its members. He also mentioned that the association could be an asset to CJ, who is currently facing difficulties.
  </p>
  <p>
  Kingsley on the other hand, believed that the association has functioned effectively to grow and sustain members. It has provided support and encouragement to its members and has been an integral part of the community through participation, volunteering, and charitable giving. It has worked so hard to promote its image and protect its reputation in society. In referring to CJ's condition, he said that he had contacted CJ through all possible avenues, but he was not responding. Ugo and others confirmed that CJ was not answering their calls as well. After much discussion, it was decided that Billy should reach out to CJ, and Emeka should speak with Eloka before the next meeting.
  </p>

   <h5> Any Other business</h5>
   <p>
   In keeping with our tradition, members warmly welcomed a brother who introduced himself as Mr. Soluchi Nwaosu, aka Soki.He is a medical doctor from the Nnewi community in Anambra, Nigeria. Soki in his speech expressed satisfaction with the organization's vision, especially its efforts to promote unity and love among the Igbos in the Northwest. He promised to be a part of this noble cause and to make himself available whenever he could
  <br/>
  Billy brought a bottle of wine to the association to officially announce his engagement party. Kingsley praised Billy for his consideration towards the association and for taking the appropriate steps to inform the members. Speaking on behalf of the organization, he assured Billy of their maximum compliance and support for the engagement party.
  </p>

  
   <h5>Finance</h5>
   <p>
   The sum of €60 was collected for the dues and was given to Kingsley.
   The motion for adjournment was moved by francis, seconded by VC at 8:00 pm.
   </p>



</main>
</div>
  )
}
export default May2022meeting




