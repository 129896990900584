import React from 'react'
import Menu from '../../../Header/DropdownContainer/Menu'
import styled from 'styled-components'

import stpatrick from '../../../image/stpatrick.jpg'
import old from '../../../image/oldstpatick.jpeg'
import tidy from '../../../image/tidy.png'
import video1 from '../../../video/dublin.mp4'
import { useRef } from 'react'
import { Helmet } from 'react-helmet-async'


const Engagment = () => {
  const videoRef= useRef();
  const setPlayBack = () => {
    videoRef.current.playbackRate = 0.2;
  };


  return (
    <>
  <Helmet>
   <title>Community Engagement</title>
   <meta name = "description" content="Igbo Cultural Association is committed to improving the cleanliness and appearance of their local area"/>
   <link rel="canonical" href="/Engagment" />
    </Helmet>

    {<Menu/>}
       <EQUALITY className='p-5 m-auto relative top-0 container'>

       <header style={{ backgroundImage: `url(${tidy})` }}>
       <div className="titles"><h1> Being Part of The Community</h1></div>      </header>
      <main>
        <div className="text-page">
          <h2>Community Engagment</h2>
          <p>
          Community engagement refers to the process of involving community members in decision-making and activities that affect them. It involves building relationships and working collaboratively with community members to identify and address community needs and challenges. Igbo Cultural Association has taken initiatives to improve the cleanliness and appearance of their local area. They participate in the Tidy Town competition every year to promote community engagement and civic pride in enhancing the local environment.
          </p>
        </div>
    
      </main>
      </EQUALITY>

    </>
  )
}

export default Engagment

const EQUALITY = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  header {
  position:relative;
margin-top:1rem;
 padding: 2rem 0;
  color:white;
  text-align: center;
  background-position:45% 42%;
  background-size: cover;
  height: 300px;
  position: relative;
}

header::befor{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

header h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width:100%;
  color: #fff;
  text-shadow: 2px 2px #000;
}
main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.text-page {
  max-width: 800px;
  margin-bottom: 2rem;
  text-align: left;

}

.text-page h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color:#000;
}

.text-page p {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #555;
  font-size:18px;
  line-height:5vh;
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 3rem;


}

.grid-item {
  position: relative;
  min-width: 400px;
  min-height: 300px;
  background-size: cover;
  background-position: 35% 45%;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  img{
    min-width:100%;
    max-width:100%;
    min-height:100%;
    max-height:100%;
    object-position: 35% 45%;
  }
}

.grid-item:hover {
  transform: scale(1.1);
}

.grid-item-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
}

@media screen and (max-width: 768px) {

  h1 {
    display: none !important;
  }

  .text-page h2 {
    font-size: 2rem !important;
  }

  .text-page {
    min-width:100vw;
    text-align: center !important;
    padding:0 2rem !important;

  p {
    font-size: 19px;
  
  }
  }
  .grid-container {
    max-width: 90vw;
  }

  .grid-item {
    width: calc(33.33% - 0.5rem);
    height: 150px;
  }
}

`