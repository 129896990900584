import React from 'react'
import { Link } from "react-router-dom";
import '../../CSS/Navs.scss'
import styled from 'styled-components'

export const Navs = () => {
  return (
    <div>
       <HEADER>
        <nav className="minutes-page-navbars relative"
        style={{
        background: ' #FFFCA8'}}
        >
          <div className="left-sections">
            <span  className='me-2 text-dark '> Members only</span>
          </div>
          <div className="right-sections">
            <u className='me-2 text-dark readings span'>Finished Reading...?</u>
            <Link to='/' target={'_parent'} className='mx-3 button-right text-decoration-none border-none shadow-none text-dark buttons' >Logout</Link>
          </div>
        </nav>
      </HEADER>
      {    <hr className="horrizontal relative top-0" 
        style={{
        border: '1px solid rgba(98, 97, 97, 0.9)', 
        margin:'5rem 0'
      }}
     />}


    </div>
  )
}
export default Navs

const HEADER = styled.div`
background: #FFFCA8 !important;
@media(max-width:992px){
  .right-sections{
    min-width:70vw;
    display:flex;
    justify-content:space-evenly;
   align-items:center;
   .span{
    font-size:1.2rem !important;
    font-weight:400;
   }
  .button-right{
  position:relative;
  left:0 !important;
  font-size:1.8rem !important;
  color: #131313;
  font-weight:bold;
background: #FFFCA8;
  }
@media(max-width:700px){
  justify-content:flex-end;
  .span{display:none !important}
  min-width: 50vw !important;

}
@media(max-width:450PX){
    min-width:30vw !important;
    max-width:30vw !important;
    position:relative;
    right:-2rem !important;
}
}
.left-sections{
  min-width: 50vw !important;
  span{
    font-size:1.3rem !important;
   font-weight: bold;
  }
  @media(max-width:450px){
    min-width:70vw !important;
    max-width:70vw !important;
    position:relative;
    left:-2rem !important;
  }
}
}
`


