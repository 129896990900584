

import React from 'react';
import Navbars from '../../MinutesPage/Navbars'
import {Container, Navbar, Col, Row } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';


function June2023Meeting() {
  return (
    <div className="Article-cover">
    {<Navbars/>}

   {/*    page body starts here */}

   <main className='main'>
     
   <h2> Minutes for June</h2>
   <p style={{fontSize:'1.1rem'}}>
   <b>Date:</b> 11/06/2023
   <br/>
   <strong>Members Present:</strong> <span className='m-present'>.
   Kingsley Agoha, Charles Obodoechina, Emeka Onwuzo, Ugo Nwankwo, Chijioke, Innocent Ike, Austin, 
   </span>
   <br/><br/>
   <strong>Members Absent:</strong> <span className='m-abscent'>
   Billy, Chidi Usanga, Soki, Chidebere, Emanuel Okozie(moved to Magherafelt) , Francis(lost a family member) and Edozie
   </span>
   </p>

<h5>Topics Discussed</h5>


<p className='text-dark'>
•	Meeting Venue Review<br/>
•	Summer Outing Event<br/>
•	Matters Arising<br/>
•	Finance<br/>

</p>



   <h5>Proceedings</h5>
   <p>
   The meeting was convened at 5:30 PM. Open prayer was said by Kingsley. Chuks presented the minutes of the last meeting. <br/><i style={{color:'blue'}}>Amendment:</i> Ike arrived late and promptly settled his dues, including €30 for the upcoming summer outing event.<br/>
   <i style={{color:'blue'}}>New members / observers:</i> A warm welcome was extended to our new members: Peter Chukwuma and Kingsley Igwe, both of whom are residents of Derry. Kingsley Alobuo, an observer residing in Dunkineely - Donegal, was also warmly welcomed.





  </p>


   <h5>	Meeting Venue Review</h5>
   <p>
   During the meeting, the Chairman addressed the change in location due to a funeral service at Stranorlar's parish church hall. Chijioke highlighted the importance of receiving advance event notice to facilitate proper planning and prevent last-minute preparations.
   Charles brought up a pricing concern regarding Letterkenny Community Centre. Sokey generously offered to negotiate a contract for the venue. Ugo, who is experienced in negotiations, also supported the contract idea to address those concerns. He promised to provide the contact information for the foreman(The Venue management), Liam, to facilitate the process.
   <br/><br/>
   Kingsley Igwe proposed the idea of establishing a dedicated fund to cover meeting venue expenses. Charles supported the idea, highlighting the value of establishing a separate fund source. Chijioke warned not to ask members for too much money directly. He suggested an alternative approach: generating funds through non-compliance penalties, such as enforcing rules like latecomers paying for their lateness. Emeka stepped forward and volunteered to take on the role of provost, given Emmanuel's relocation. The Chairman welcomed Emeka's offer and officially appointed him to the position. Furthermore, The Chairman made an announcement that the website has been completed and urged members to review it and offer feedback. He also requested observations or feedback from members. Ugo suggested sharing the web address with the members.

  </p>


  <h5>Summer Outing Event:</h5> 
  <p>The focus of the summer outing discussions revolved around the venue.  Emeka mentioned that his place wouldn't be available due to his wife's work. 
After extensive deliberation, it was finally resolved to consider potential venues such as Stranorlar at the parish church hall, Letterkenny Community Centre, and Lurgybrack Open Farm.<br/>
  </p>

  <h5>Matters Arising:</h5>
  <p>
  Udemezue said that the main difference between this country and Nigeria is knowledge, and that's why we're all here, as knowledge is important. He mentioned that if someone is only looking for money, that person is missing the point. He talked about how the Igbo people used to have a strong role in Nigeria, but they're losing that influence to the Hausa and Yoruba due to Nnamdi Kanu's actions. He also mentioned that in the past, Nigeria had social welfare programs like giving housing and car allowances to college graduates.<br/><br/>
  Ike Nwadike raised a question about keeping track of our meeting drinks. Charle gave an update on the drink bank. Emeka asked for the announcement of the next meeting's host. Obinna proposed announcing the host during the meeting.
  Another notable occurrence during the meeting was the act of drink donation. Kingsley Igwe made a generous contribution of 3 cartons of beer as a gesture of appreciation for becoming a member. Additionally, Charle donated a hot beverage to support his responsibility as the meeting host.
  </p>


  

   <h5>Finance</h5>
   <p>
The sum of €140 and £55 were collected for dues and handed over to the treasurer. Family outing Event – Contribution: €30(Ike) + €30(Joseph) = €60; member registration: £15(Peter)
The motion for adjournment was moved by charles, seconded by Austine at 7:15pm.  
The next meeting is scheduled for Agust 13th, 2023. 
The closing prayer was said by Kingsley. 
</p>

   <Container>
         <Row className='d-flex justify-content-center align-items-center'>
           <strong><h4 className='text-danger p-2 px-0 sumary'>
             Summary of the Dues</h4>
           </strong>
           </Row>

           <div className='tab' style={{minWidth:'400px',maxWidth:'400px'}}>
   
         <Table striped bordered hover variant="dark">
         <thead>
           <tr>
           <th>NAMES</th>
           <th>DEUS</th>
           
      
           </tr>
         </thead>

         <tbody>
         <tr>
           <td>Emeka</td>
           <td>€10 </td>
        </tr>
         <tr>
           <td>Soki</td>
           <td>paid in advance</td>
        </tr>
         
         <tr>
           <td>Joseph</td>
           <td>€10 </td>
        </tr>
        <tr>
           <td>Ugo</td>
           <td>€10</td>
        </tr>
         <tr>
           <td>Austin</td>
           <td>£10 </td>
        </tr>
         <tr>
           <td>Chuks</td>
           <td>€10 </td>
        </tr>
         <tr>
           <td>Kingsley</td>
           <td>£10 </td>
        </tr>
      
        
         <tr>
           <td>Francis</td>
           <td>not paid </td>
        </tr>

         <tr>
           <td>Charley</td>
           <td>€10 </td>
        </tr>
         <tr>
           <td>Emmanuel</td>
           <td>not paid </td>
        </tr>
         <tr>
           <td>Kingsley Iqwe</td>
           <td>£20 -June & July </td>
        </tr>
         <tr>
           <td>Innocent Ike</td>
           <td>€10 </td>
        </tr>
         <tr>
           <td>Peter</td>
           <td>not paid </td>
        </tr>
        </tbody>
         </Table> 
         </div>
       </Container>

 </main>
</div>
);
}

export default June2023Meeting;


         


           
              
      