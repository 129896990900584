
import React from 'react';
import Navbars from '../../MinutesPage/Navbars'
import {Container, Navbar, Col, Row } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';


function Nov2022Meeting() {
  return (
    <div className="Article-cover">
    {<Navbars/>}

   {/*    page body starts here */}

      <main className='main'>
     
        <h2> Minutes for November</h2>
        <p style={{fontSize:'1.1rem'}}><b>Date:</b>19/11/2022
        <br/><br/>
        <strong>Members Present:</strong> <span className='m-present'>Kingsley, Obodoechina, Francis, Emeka, Ugo, Chuks, Emmanuel, Obinna, Chidebere, Edozie, Chidi Usanga and Nwadike.</span>
        <br/><br/>
        <strong>Members Abscent:</strong> <span className='m-abscent'>CJ and Billy</span>
        </p>


        <h5>Proceedings</h5>
        <p>
        The meeting was called to order at 6:00 PM. The open prayer was led by Kingsley. The minutes of the last meeting were read by the chairman. It was approved by Obinna and seconded by Chuck. The main topics discussed were Bank Signatory, the Charity Status Application, and the End of Year Party. Minutes were taken by Francis. The chairman welcomed everyone present at the meeting and asked about Billy and CJ who were absent. While no one could give a detailed account of them, he further asked for an update on the memorial service held in memory of our late patron Jerry Obi. Kingsley reckoned that the program was successful and well attended and those who could not attend sent a message. Both the family of Jerry Obi and his church community were very happy and appreciative.
          .</p>

   
        <h5>Bank Signatory</h5>
        <p>
        The Chairman asked for an update regarding the change of signatory on our account.
        Chuks in response said that they were not able to meet due to individual schedules and other commitments. However, he noted that the delay does not mean that they have forgotten about it. He assured the association that the issue would be rectified as soon as possible.

        </p>

        <h5>Charity Status Application</h5>
        <p>
        The Chairman apologized for the delay in filling up the application form as planned. He took the blame upon himself for failing to organize a virtual meeting in which the form could have been filled. He noted that his action was not intentional, but rather, due to some circumstances beyond his control. He promised that he would make up for the delay as soon as possible. Kingsley thought that the chairman's apologies should be accepted as his reasons are cogent enough - stressing that unexpected challenges are inevitable, and it could happen to anyone.
        </p>

        <h5>End of the year Party</h5>
        <p>
        Following the decision reached at the last meeting, Emeka and Francis were asked for an update on the party venue. Emeka said that two places he saw were not ideal for the program. One is on the outskirts of the target area – that’s in the Strabane area, and the second one has a food restriction. Francis, on the other hand, noted that he had found a place around the target area – a hall beside St Mary’s Catholic church in Stranorlar. Also, said he has already reached an agreement with the property's management to use the hall. However, the agreement has not been finalized because the party date has not yet been chosen. He promised to provide a final update in a week’s time. Regarding the Christmas-party date, the 17th and 18th of December were suggested. The 11th of December was also included as an alternative date, considering that 17th or 18th  may not suit everyone because of work and other engagements over the Christmas season. It was agreed by everyone.
        </p>

        <h5>Under Matters Arising</h5>
        <p>
        The Chairman asked Ugo why he had refused to serve as financial secretary, which he was elected to do. Ugo maintained that he did not refuse to work for the association nor was his inactivity due to a lack of interest. Instead, it was due to personal commitments and work demands. He further suggested that new members should be given the opportunity to assume the position. The suggestion was considered impromptu, so he was asked to withdraw it, and he obliged. Another discussion brought forward was an invitation from the Igbo Union Belfast. Kingsley supported the idea of honouring the invitation, and also encouraged members to support it as it will help us expand our network. In addition, he promised to attend the event. Francis also promised to join him. In the end, it was decided that everyone should meet at Kinsley's house in Derry, after which we would all go to Belfast together.
        </p>
        <h5>Any Other Business</h5>
        <p>
        In a spirit of reconciliation, fairness, and forgiveness, Chief Nwadike re-joined the association. Members thanked him for his bravery and welcomed him back. The chairman assumed responsibility for speaking with him about the other procedures involved. Jerry Obi Jr. also registered as a member, and Emmanuel takes on the obligation of paying his dues each month.
        </p>
        <h5>Finance</h5>
        <p>
        The sum of €100 was collected and given to the treasurer. The motion for adjournment was moved by Chuks, seconded by Francis at 8:30 pm. The next meeting is scheduled for Feb 12th, 2023. The closing prayer was said by Emmanuel.
        </p>

        <Container>
              <Row className='d-flex justify-content-center align-items-center'>
                <strong><h4 className='text-danger p-2 px-0'>
                  Summary of the Dues and Payement</h4>
                </strong>
                </Row>

                <div className='tab' style={{minWidth:'400px',maxWidth:'400px'}}>
        
              <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                <th>NAMES</th>
                <th>DEUS</th>
           
                </tr>
              </thead>

              <tbody>
              <tr>
                <td>Emeka</td>
                <td>€10 </td>
                
             </tr>
              <tr>
                <td>Ugo</td>
                <td>€10 </td>
              
             </tr>
              <tr>
                <td>Chuks</td>
                <td>€10 </td>
          
             </tr>
              <tr>
                <td>Kingsley</td>
                <td>€10 </td>
             </tr>
              <tr>
                <td>Obinna</td>
                <td>€10 </td>
             </tr>
              <tr>
                <td>Emmanuel</td>
                <td>€10 </td>
         
             </tr>
              <tr>
                <td>Nwadike</td>
                <td>€20 </td>
                
             </tr>
              <tr>
                <td>Francis</td>
                <td>€10 </td>
            
             </tr>
              <tr>
                <td>Charley</td>
                <td>€10 </td>
            
             </tr>
             </tbody>
              </Table> 
              </div>
            </Container>

      </main>
    </div>
  );
}

export default Nov2022Meeting;
