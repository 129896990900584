import React from 'react'
import Modals from '../Header/DropdownContainer/Modals';
import styled from 'styled-components'
import Menu from '../Header/DropdownContainer/Menu';
import { Helmet } from 'react-helmet-async'


const BlogPages = () => {
  return (
    <>
         <Helmet>
   <title> THE HISTORICAL ORIGIN OF ORA ERI COMMUNITY IN ANABRA STATE, NIGERIA</title>
   <meta name = "description" content="ORA ERI a town located in Aguata LGA in Anambra State of Nigeria with 5 villages namely: Obiuno, Umunriofia, Obinri, Umudike and Ebenato."/>
   <link rel="canonical" href="/LanguageDetail" />
    </Helmet>
         <AcordioCover className='container-fluid relative'>
            { <Menu mode={Modals} className='fixed top-0 left-0 linkup' style={{

            marginTop:'-2rem !mportant'}}/>}
            <Acordios className='container'>

            <div className="accordion acordio-inner" id="accordionExample">
                <h2 className=' text-center pt-2 pb-4'>
                  The Historical | Traditional Existence of Villages in Nigeria
                </h2>
         <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
       <button className="accordion-button acordio-btn text-center text-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        THE HISTORICAL ORIGIN OF  ORA ERI COMMUNITY IN ANABRA STATE, NIGERIA
      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <h3 className='text-center  pt-5'><strong>Brief Overview of Ora Eri Community</strong></h3> 
       <p className='acordio-text'> A town located in Aguata LGA in Anambra State of Nigeria with 5 villages namely: Obiuno, Umunriofia, Obinri, Umudike and Ebenato. A little but mighty town known of producing great people with great potentials in all works of life. A town that is proud to have produced the former Chairman, Aguata LGA, the pionner State Chairman, Nigeria Labour Congress, an elder stateman and a renowed politician in the name of Sir Comrade N.K Agba. The town that has produced former Secretary, World Health Organisation, House of Assemble member, Commissioners, Professors, medical Doctors, Academia, Industrialist, Pastors, Bishops, Revrends, successful businessmen etc.

        <p>The King of the town is called EZENRI while OKPALA is the traditional prime minister. A town that has rich cultural heritage with an ancestral forest that has turn as a tourist site to all and sundry. it shared boundary with Igboukwu, Aguluzigbo, Ichida, Adazienu and Akwaeze.  The traditional ruler, HRH Ezenri Emmanuel O. Nriagu, Ezenriagu II and Ezenri XXIst, was a civil servant, a former PG, former Secretary General of the town Union with a sound educational background and a deep rooted catholic faith.</p>
        In the interview with Jonekay Echezonachukwu Emejulu and Ijeoma Ekwusi held at the Ezenri’s palace ground, Ofia Ezenri Oraeri, he dwelt on the ancient background of the Oraeri people.  He also mirrors on the reason Oraeri is regarded as the Spiritual Arbiter cum Avatar of Igboland .(cleansing of abormination of any form in the igbo land)..  He mentioned the lingering land dispute in the community by one of the villages and the court’s verdict.  He also reveals on his nouveu plans for the Ofia Oraeri, among others.
      </p>
      <p className='acordio-text reduce-top'>
      <h5><strong>How Ora-Eri came About</strong></h5>
      <p>The history of the town emanated from the Jews.  It was believed that the first occupant (Jews) spoke in the Hebrew language until further generations were born and thus had a corrupted version of the language, which has come to be known as the Ibo language. Both languages have so many similarities.  The behavioural patterns between the Jews and the Igbos have so many commonalities, like the circumcision is done on the eight day. Both have four days (izu) in the week, which are the Eke, Afor, Oye and Nkwo unlike the western clime which has 7 days in a week.</p>
<p>Also, in the Bible, Gad and Eri are being mentioned as the descendants of Jacob, the grandson of Abraham. In a documented research by the English Archeologists, Thurstan Shaw, showing that the apparent Igbo-ukwu archaeological site were those of the ancient Nri-Eri kingdom of Ora-eri that lost the archaeological sites to the Igbo-ukwus during the war. Under Igboukwu’s name, the site became popularized although it is still known to the Igbo-ukwus, that the sites truly belonged to the Ora-Eri people.  This is so because it is the Ora-Eri alone that has the Eze-Nri-Eri priest- kings.  Thurstan Shaw who excavated the sites acknowledged the fact that the said archaeological sites where Eze-Nri of Ora-Eri lived “… surely represent something exceptional … The repository does not contain vessels and ornaments in everyday use, nor did the burial chamber contain the remains of an ordinary commoner.”</p>

<p>He went to show that the said Eze-Nri–Eri, “Divine Kingship” has “a considerable vesting of social and spiritual power and prestige,” and of course with the “accumulated wealth” and unique history behind it.To this end, according to Ora-Eri tradition of origin, the first Eze-Nri was Eri, who beget Menri Eri the 2nd, who consecrated his two sons as the next Eze-Nri. Two separate dynasties of Ora-Eri and Agukwu came into being. Namoko was responsible for Ora-Eri line, while Ifiukwuanim for Agukwu. And then Avo who was the Ezenri-Nri the fifth, it sprang in that succession until Ezenri the 21st which I am till date. The Ezenri is not hereditary but by divine intuition; who will become the next Ezenri after the demise of a particular Ezenri is by divine revelation. So many mysterious things begin to happen to a chosen one until he is eventually crowned as the Ezenri.The Ancient palace of the Ezenri is housed in a miraculous forest enclave known as Ofia eri Ezenri, a land where no palm tree can ever thrive in the soil. A spectacular feature of the forest is that there are two special Iroko trees that stand at the entrance gate to the palace that dated back to so many centuries.  This wonderful forest was the abode of 20 generations of Ezenris. </p>

<p>They have a traditional seat which they normally sit on in their Obi called Mgbidi. Before one enters the Ezenri’s palace, there is a barricade where women are not expected to cross called the Ejete-ebe. Here only a man can pass through to the palace.  It has been observed from time immemorial till now. In Oraeri kingdom, an Ezenri cannot see a corpse, right from the first Ezenri to the present have adhered to this practice.  Any ambulance carrying a corpse is not expected to pass through the Ofia Ora-Eri.  They can only convey the corpse either from behind or other adjoining streets but not passing through the Ezenri’s palace to the adjoining roads. It is forbidden by the law of the land.  Where an Ezenri accidentally stumbles on a corpse, it requires series of spiritual cleansing to ward off imminent or future doom for the land. Oral mythology has it that when the 6th Ezenri, Ezennagu 1st got up one morning to inspect his palm fruits in the palace ground, he stumbled on a corpse. While ritually purifying himself he consulted his spiritual adviser, Okpala (head of the Adamas) and requested that no palm tree should ever spring from the soil again.  And from thenceforth till the present, no palm tree has ever sprung forth from the Ofia Oraeri soil. To this day every attempt to grow palm tree in that portion of land has failed, defiling every scientific explanation.</p>
<p>Now the Adamas, who are ritual Priests, and are bestowed with unilateral power to crown the Ezenri, when an Ezenri goes to meet his ancestors they hold forte until a new Ezenri is crowned and within that period there is a ceremony they perform called the Ncheta Ezenri (remembrance of the Ezenri). However the highest title an Adama can attain is an Okpala.The principal duty of the Oraeri people is that they act as the priest of the Nri people.  Anywhere sacrilege is commited in any part of Igboland like suicide that is termed a sacrilege it is the Oraeri people that have over the years been bestowed with this singular authority to perform such sacrifices of removing the corpse.  The Ezenri will give authority to any Oraeri man to expunge any sacrilege.  It is part of our inheritance from Israel; hence we are from the Israeli genealogy (agburulo). Again when such abominable act is committed in such community, the people of the town have no right to touch the dead body, otherwise they will face the wrath of the gods. It is only an Oraeri indigene that is mandated to remove the corpse.  And that practice still stands till date.</p>


<p>Without mincing word, It is interesting to know that where the present Awka prison is sited today was a big mystical forest.  When the government wanted to develop the place, so many people were called and could not pull down the forest owing to its mystical powers.  It was the Oraeri people that were invited, who performed the traditional rites before it was further developed. It was documented and stored in the historical archives of the state. The symbol of authority and power of a typical Oraeri man is the Ofo.  The Ofo symbol is gotten from the Ofo tree which is not easily found as it does not grow anyhow or anywhere.  The symbol falls naturally on its own from the parent tree.  It is a symbol of justice and equity in Igboland. Anyone who holds an ofo must be sure to be upright and just. The ofo has the power to react negatively to the holder if he is found wanting or corrupt. Another sacred object is the Otunsi which is a sacred immunizer. This is the spiritual force which the Nri people use to perform all their perfunctory functions and traditional rites centuries ago all the other Nri towns including surrounding communities used to come to Oraeri to pay homage (Ibu-Ihu) to the Ezenri which he equally reciprocated as he fetes the visitors with yam seedlings and a peculiar kind of manure that makes for high yield of fertility which they use in their farms.  Anyi is the name of the special ingredient added to the yam to make it yield well; it is similar to the modern-day fertilizer.  It is only the Oraeri people that knows and performs this act. </p>






      </p>
      </div>
    </div>
  </div>
  <div className="accordion-item ">
    <h2 className="accordion-header" id="headingTwo" 
     style={{textTransform:'uppercase', color:'white'}}>
      <button className="accordion-button collapsed acordio-btn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      ODABARU IZIKIEL IN ISI-UZU LOCAL GOVERMENT, ENUGU STATE | WHAT IS IT
      </button>
    </h2>
    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <h5 style={{textTransform:'uppercase'}}>ODABARU IZIKIEL IN ISI-UZU LOCAL GOVERMENT | WHAT IS IT
           </h5>
        <p>Still under research... Contribution needed</p>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingThree" 
   >
      <button className="accordion-button collapsed acordio-btn text-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"  style={{textTransform:'uppercase !important', color:'white'}}>
       EKE OGBA | WHAT IT MEANS TO PEOPLE OF OGBUNIKE 
       </button>
    </h2>
    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       <h5>What Eke Ogba means to the People of Ogbunike</h5>   
        <p>Still under research... Contribution needed</p>
      </div>
    </div>
  </div>
</div>

            </Acordios>
    </AcordioCover>
    </>
  )
}

export default BlogPages
const AcordioCover = styled.div`
min-height:100vh;
min-width:100vw;
overflow-y:scroll;
box-sizing:border-box;
margin:0;
padding:0;
background: #05386b !important;
.accordion-item{
 max-width:800px !important;
 margin:0 auto;
 p{
  font-size:17px !important;
  line-height:5vh !important;
  font-family: Arial, sans-serif !important;
 }
}
.linkup{
    background:transparent !important;
}
`

const Acordios = styled.div`
position: absolute;
top:20%;
left:0;
right:0;
.acordio-inner{
    min-width:100% !important;
    min-height: 100% !important;
}
.acordio-btn{
background: #fc4445 !important;
box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
border:none;
color:white;
}
.accordion-body{
    
    .acordio-text{
        padding: 2rem 4rem;
        :not(h5){ font-family: 'Ibarra Real Nova', serif;
        font-weight:500;
        @media(max-width:992px){
            padding: 2rem 1rem;
        }
    }
       
        
    }
    .reduce-top{
        margin-top:-2rem !important;
    }
    

}
`