import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";
import TopMenu from "../Header/DropdownContainer/TopMenu";
import { Link } from 'react-router-dom';
import { NavLink } from "react-bootstrap";

const  FoodDetails = () =>{
  let style = {
    color:'blue',
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: '20px'
  }
  return (
    <>
      <Helmet>
   <title>Food as a Reflection of Cultural Identity and Social Practices</title>
   <meta name = "description" content="EUnderstanding certain concepts can serve as a guide to self-discovery. For example, how food influences and reflects cultural identity and heritage is one such concept"/>
   <link rel="canonical" href="/" />
    </Helmet>

       {<TopMenu/>}
  
    <CUISINE className="container relative ">
      <h1 className=" py-10 text-center">Food as a Reflection of Cultural Identity and Social Practices</h1>
      <p className="text-dar">
        Food plays a significant role in shaping and expressing cultural identity and heritage. The way people prepare, serve, and consume food can reveal a great deal about their cultural traditions, beliefs, and values. For instance, in some cultures, the process of cooking and sharing food is a communal activity that involves multiple family members and friends, while in other cultures, food preparation is a more solitary activity.
        The ingredients, spices, and flavours used in a particular dish can also reveal a lot about a culture's history, geography, and resources. For example, the use of spices such as cumin and turmeric is common in Indian cuisine, reflecting the country's history of trade and cultural exchange with other countries. Similarly, the use of seafood in many coastal regions reflects their proximity to the ocean and their reliance on fishing as a way of life.<br/>
        Moreover, food is often used as a way to bring people together and strengthen social bonds. Sharing meals with friends and family is a common way to celebrate milestones, bond over shared experiences, and express affection and hospitality. The act of preparing and sharing food can also be a way of passing down cultural traditions and knowledge from one generation to the next.
        Overall, food is not just a means of sustenance but a cultural phenomenon that reflects the traditions, values, beliefs, and practices of a particular group of people. Understanding the cultural significance of food can help us appreciate the diversity of human experience and connect with people from different backgrounds.
      </p>
     
      <p>
        This is especially true for the Igbo people of Nigeria. The Igbo culture places a high value on hospitality and uses food as a way to welcome guests and show generosity.
        The traditional Igbo cuisine includes a variety of dishes and drinks, such as Egusi soup, yam, Nkwobi, Abacha, and Palm wine. These foods are not just a source of sustenance, but also deeply connected to the cultural identity and heritage of the Igbo people. Many of them have been passed down from generation to generation. Some of them also play a significant role in Igbo religious and spiritual practices. For instance, yam is regarded as a sacred crop in Igbo land and is used in many important rituals and ceremonies.
        If you wish to learn more about Igbo cuisine and the preparation, click the links below.
      </p>
      <div className="links d-flex flex-column px-3">
      <NavLink href = 'NativeFood/2' style={style}>How to prepare Abacha </NavLink>
      <NavLink href = 'NativeFood/3' style={style}>Nkwobi recipe and the preparation</NavLink>
      <NavLink href = 'NativeFood/1' style={style}>The cultural value of Palm wine</NavLink>
      </div>
      

    </CUISINE>
    </>
  );
}

export default FoodDetails;
const CUISINE = styled.div`
top:6rem;
max-width:80% !important;
margin: 0 auto 3rem;
display: flex;
flex-direction: column;
align-items: center;
padding: 20px;
@media(max-width:786px)
{
  padding: 0 0  4rem!important;
  margin-bottom:4rem !important;
  top:
}  

h1{
    color:#333 !important;
    font-weight: bold;
    font-family:Arial, Helvetica, sans-serif;
  }

p{
    line-height:5vh !important;
    font-size:18px !important;
    padding: 0 1rem;
    color: #333;
}

.links {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:flex-start;
  width: 100%;
}

.food-links a{
  display: block;
  text-align: left;
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  color: #333;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.food-link:hover {
  background-color: #aaa;
  color: #fff;
}

@media only screen and (min-width: 768px) {
  .food-container {
    max-width: 768px;
  }
  
  .food-links {
    justify-content: space-between;
  }
  
  .food-link {
    flex-basis: 30%;
  }
}



`