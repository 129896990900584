import React, { useState } from 'react';
import styled from '@emotion/styled';
import {Button, Container} from 'react-bootstrap'
import Menu from '../Header/DropdownContainer/Menu'
 import image1 from '../VIDEOS/fathers.mp4'
import image2 from '../VIDEOS/kids.mp4'
import image3 from '../VIDEOS/menvideo.mp4'
import image4 from '../VIDEOS/mothers.mp4' 
import image5 from '../VIDEOS/Familydance.mp4'
import image6 from '../VIDEOS/relay.mp4'
import image7 from '../VIDEOS/masq.mp4'
 import image8 from '../VIDEOS/wedding.mp4'
 import poster from '../image/dancing.png' 


const videos = [
  {
    url: image1,
    description: '@Xmas Party 2022  Stranorlar',
  },
  {
    url: image2,
    description: '@Summer Party Ballybofey',
  },
  {
    url: image3,
    posterUrl: 'poster',
    description: '@Xmas Party 2019  Derry',
  },
  {
    url: image4,
    description: '@Xmas Party 2022 Stranorlar',
  },
  {
    url: image5,
    description: '@2019 Xmas Party Derry',
  },
  {
    url: image6,
    description: '@2022 Relay4Life Event Letterkenny',
  },
  {
    url: image7,
    description: '@Africa day Festival  Letterkenny',
  },
  {
    url: image8,
    description: '@Mr Jerry Obi Wedding Letterkenny',
  },

];



const Videos = () => {

  const changeBackground = ()=>{
    if(window.scrollY>= 80){
    
    console.log(window.scrollY)
    }
  }

  changeBackground ();
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const currentVideo = videos[currentVideoIndex];

  const handleVideoSelect = (index) => {
    setCurrentVideoIndex(index);
  };

  return (
<>
{< Menu/>}
<div className='bg-zinc-900 text-light relative video-cover container-fluid top-0'>

    
<div className="relative row top-10 video-playlist">
<div className="video-wrapper relative col-md-12 col-lg-7  ">
<video src={currentVideo.url} poster = {currentVideo.posterUrl} controls autoPlay width="540" height="360" />
<div className="video-details">
<h4 className='text-black py-2'>{currentVideo.description}</h4>
</div>
</div>
<div className="playlist-wrapper  col-md-12 col-lg-5">
{videos.map((video, index) => (
          <div className='playlist-item' key={index} onClick={() => handleVideoSelect(index)}>
            <BUTTON className='bg-transparent border-0 outline-none'>
            <i className="fa fa-play-circle-o"></i>
            <span className='vd-desc'>{video.description}</span>
            </BUTTON>
          </div >
        ))}
      </div>
    </div>
    </div>
    </>
  );
};

export default Videos;


const BUTTON = styled(Button)`
  border: none !important;
  outline: none !important;
 display: grid;
 grid-template-columns:repeat(2, auto);
 justify-content:start;
 align-items:center;
 text-align:left;
 gap:10px;
 &:focus{
  box-shadow:none !important;
 }
i{
color: #fbf8f8 !important;
font-size:2rem !important
 }

 
  
`


