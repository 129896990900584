import React from 'react'
import Menu from '../../../Header/DropdownContainer/Menu'
import { Outlet } from 'react-router-dom';
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async';

const Acheivement = () => {
  return (
    <>
    <Helmet>
   <title>Acheivement</title>
   <meta name = "description" content="We promote cultural understanding, charity, and appreciation, thereby contributing to the creation of a more cohesive society"/>
   <link rel="canonical" href="/Acheivement" />
    </Helmet>
    {<Menu/>}
        <MISION className='container px-16 py-5 relative top-6'>
            <h1 className='p-3 text-center text-dark' style={{fontWeight:'bold'}}>Our Activities in a Glance</h1>
          <section className='p-3 text-left' > 
          <p>
        Igbo Cultural Assocition is a cultural and charitable group from Nigeria residing in Northwest Ireland. The mission is to foster peace and unity by promoting cultural integration, diversity and volunteerism. The vision is to create a harmonious and inclusive society that celebrates diversity, respects cultural heritage, and fosters unity through collaborative efforts and active community involvement.
          Since our inception, we have remained committed to achieving our goals.
          </p>
           <p>
          Through our charitable program, we have made a significant difference in the lives of those we serve. We have made donations to Irish Wheelchair, the Irish Cancer Society, Eyecare in Africa and other organizations. Alongside our commitment to equality and diversity, we have collaborated with various groups to campaign against discrimination and abuse based on ethnicity or culture.
          </p>
          <p>
           Furthermore, our involvement in cultural integration and promotion in Ireland is extensive. During the St Patrick's Festival, we showcase our cultural values such as music, dance, and fashion, to entertain and engage the community. We also use our annual end-of-the-year family get-together program to bring people from different races together, where we serve our traditional foods and perform our cultural dances. We have also supported families of both members and non-members of the organization during difficult times, providing solidarity and repatriation assistance to their loved ones who did not want to be buried abroad.
          </p>
          </section>
          <section className='p-3 text-left'>
          <h3 className='p-3 text-center text-dark' style={{fontWeight:'bold'}}>
            
          Analyzing the Group Based on their Commitment:
            
            </h3>

         
<p>The Igbo Cultural Association has shown a strong commitment to cultural integration, as evidenced by their efforts to bring people from diverse backgrounds together and showcase their cultural values through entertaining events. By promoting cultural understanding and appreciation, the association is contributing to the creation of a more cohesive society.
    </p>
    <p>
The group's commitment to equality and diversity is reflected in their efforts to fight against racial discrimination and ethnic/cultural abuses. By recognizing and valuing differences, they create a more inclusive and equitable community.
Through their charitable programs, the group has made a positive impact on the lives of those they serve, including donations to different organizations. 
</p>
<p>Their support for families of both members and non-members during difficult times reflects their compassion and commitment to helping others.
Finally, the group's supportive initiatives, such as assisting with repatriation for those who wish not to be buried abroad, demonstrate their willingness to go above and beyond to provide practical support and assistance to those in need.
</p>


 </section>
</MISION>
    
    </>

  )
}

export default Acheivement;
const MISION = styled.div`
max-width:80%
h1, h3{
    min-width:100vw;
}
p{
    color:#333;
    font-family:'Ariel', sans-serif;
    line-height: 5vh;
    font-size:18px;
}
@media(max-width:786px){
    p{
    line-height: 4.5vh !important;
    font-size:19px !important;
    }
   
    }

`