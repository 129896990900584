
import React from 'react';
import Navbars from '../../MinutesPage/Navbars'
import {Container, Navbar, Col, Row } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';
import styled from 'styled-components'


function Oct2019Meeting() {
  return (
    <div className="Article-cover">
    {<Navbars/>}{/*  */}

   {/*    page body starts here */}

   <main className='main'>
     
   <h2> October 2019 Minutes</h2>
   <p style={{fontSize:'1.1rem'}}>
   <b>Date:</b> 13/10/2019
      <br/><br/>
   <strong>Members Present:</strong> <span className='m-present'>.
   Kingsley, Obodoechina, Francis, Emeka, Ugo, Chuks,Nchedo, Eloka and Billy
   </span>
   <br/><br/>
   <strong>Members Absent:</strong> <span className='m-abscent'>
   CJ and Jerry Obi 
   </span>
   </p>

   <h5>Proceedings</h5>
   <p>
   The Meeting was called to order at 5:45PM. Minutes was taken by francis, Open prayer was led by Kingsley, The minutes of the last meeting were read by francis and motion to approve it was made by Kinsley, seconded by Chuks 
  </p>

  <h5>Agenda</h5>
   <p>
   <UL className=''>


                <li>a. Charitable Status</li>
                <li>a. End of Year Party</li>
                <li>b. Igbo Association Dublin Update</li>
                <li>c. Our 10th Anniversary/Iwaji Planning</li>
                <li>d. Any Other Business</li>   
    </UL>
  </p>

   <h5> Charitable Status</h5>
   <p>
   Vc Chariman reliable imformed the associaton that the constitutional amendment committe has met and the consitution has been amended accordingly. Both the name of the associaton and Members previledge were touched. Registration fee has been changed to €25 for the employed and €15 for the unemployed. The amended constitution will be reviewed further, after which it will be circulated before the next meeting.
   </p>

   <h5> End of Year Party</h5>
   <p>
   <strong><u className='text-danger text-bold'>VENUE:</u></strong>  Vc suggested iLico Studio in Derry, Emeka suggested Finn Valley Stranorlar and Ugo suggested Community Center Letterkenny. Considering the size, cost and suitabilty of the locations including their availabity by date, iLico Studio was chosen. The issue of immigration related to Brexit and hard borders was raised by Nchedo, but no worries were expressed.<br/>
   <strong><u className='text-danger text-bold'>Cooking:</u></strong>  Kingsley confirmed that the women had consented to make the food and that a meeting had already been scheduled to discuss terms. He suggested that we agree on an amount that we would be prepared to spend on food. Françis suggested 300 euros. Some members said 400 euros and others said 350 euros. During a quick vote, 350 euros were approved. In the end, 400 euros were agreed upon for logistical reasons. Furthermore, members were asked to decide whether a simple family get-together or an elaborate party would be more suitable for them this year. It was agreed to keep it low-key and members could bring no more than three invitees.
   </p>
  
   <h5>Igbo Association Dublin Update</h5>
   <p>
   The invitation was not honored due to some circumstances beyond our control and apologies have already been sent to the group expressing our deepest concerns. To show our support, VC suggested we send them 50 euros, just as we did with the Aloma group. There was no disagreement.
   </p>

   <h5>Our 10th Anniversary/Iwaji Planning:</h5>
   <p>
   Chairman proposed pre-event planning for each event on the line, as it will help us to understand everything that needs to be done, or not done, before the event in order to ensure effective implementation and the attendee experience. According to Emeka, both Iwaji and Xmas parties should be held first, with dues being raised to €20 as part of the preparation, and hybrid planning should be used. Chuks believed that Iwaji and 10th anniversary should come first due deep connection they have with what we represent as a group and Igbos. He also supported the idea of pre-event planning as it will enhance the best use of resources. In addition, he recommended August 2020 as a suitable month for the events. Kingsley supported Chuks, but recommended cancelling the Xmas Party for that year "2020" in order to avoid conflicts in schedules and shortages in resources. In the end, it was agreed that the event would take place during the second week of September, 2020 and the event committee will be formed at the next meeting
    </p>

    <h5> Any Other Business </h5>

   <p>
   Nchedo believes Xmas Part is not a culture or  tradition of Igbos, so he will not support it. He recommended the New Yam Festival instead, arguing that NYM is a significant Igbo culture/tradition and celebrating it is essential for communicating Igbo culture to our children. Also, it would promote individual and family social life by bringing them together under the umbrella of Ndi Igbo cultural and life style. In addition, he said that the association had not been as active as texpected. He urged them to buckle up and be more engaged. Contrasting the notion of activeness, VC completely disagreed with Nchedo, highlighting that the Xmas party has always been a successful approach to promoting social engagement and our cultural values. Obinna believed that the association had been active enough, but Nchedo probably came during the time when things were quite hectic due to the pandemic.
            </p>
            <p>Following Nchedo's argument regarding activeness, Ugo brought up football and wrestling as ideal activities for our integration objective. Francis reminded the association that we are the custodians of Igbo tradition in Ireland, and as such, our decision should be aligned with our goal to anticipate and promote Igbo culture 
              <br/>
              <strong className='text-danger'><u>New Membership/Registration:</u></strong>  During the meeting, Uche Alum from Enugu state of Nigeria expressed interest in joining the group. However, he noted that his current situation might make it challenging for him to pay dues and attend meetings on a regular basis. The Chairman and Vice-Chairman accepted the condition and also commended him on his courage and interest in building rapport with his brothers. In Chuk's opinion, he should pay dues only at meetings that he is available. Obinna objected, maintaining that he should pay his dues accordingly like all other members. In the end, it was resolved that he should continue to pay his dues but be exempt from further contributions pending when his condition improves.
    </p> 
   
  
   
  
   <h5>Finance</h5>
   <p>
   The sum of €145 was collected, €110 was given to Ugo for thanksgiving invite and meeting hosting. the balance was given to the treasurer. The motion for adjournment was moved by Chuks, seconded by Francis at 7:30 pm. The next meeting is scheduled for Nov 10th, 2019. The closing prayer was said by Francis. 
   </p>

  
</main>
</div>
  )
}
export default Oct2019Meeting


     const UL = styled.ul`
     li{
  margin-left:-2rem !important;
     }
     ` 





            

         
               
      

           

         
         
     




