



import React from 'react';
import Navbars from '../../MinutesPage/Navbars'
import {Container, Navbar, Col, Row } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';


function April2023Meeting() {
    const myList = ['Permanent Meeting Venue', 'Family Summer fun day', 'Membership', 'Our Website', 'Any other Business ', 'Finance '];   
    const listItems = myList.map((myList)=>{   
    return <li>{myList}</li>;   
}); 
  return (
    <div className="Article-cover">
    {<Navbars/>}

   {/*    page body starts here */}

   <main className='main'>
     
   <h2> Minutes for April @Francis</h2>
   <p style={{fontSize:'1.1rem'}}>
   <b>Date:</b>  16/04/2023
   <br/><br/>
   <strong>Members Present:</strong> <span className='m-present'>.
   : Kingsley, Obodoechina, Emeka Onwuzo, Emmanuel Okezie, Chijioke Ogujawaa, 
   Joseph Opara, Ike Nwadike and Francis
   </span>
   <br/><br/>
   <strong>Members Absent:</strong> <span className='m-abscent'>
   Billy, Ugo, Chidi Usanga, Chidiebere, Sokey and Innocent.

   </span>
   </p>
   <h5>Agenda</h5>
   <div className='text-dark my-2'>
    {myList.map(list=>
        <li>{list}</li>
        )}
   </div>

   <h5>Proceedings</h5>
   <p>
   The Meeting was called to order at 5:30 PM. The Chairman led an open prayer, 
followed by Elder Chijioke who graciously offered kola nut to the God of Umu Igbo, asking for liberation, 
favor, and abundant blessings. The minutes of the previous meeting were read by Francis and approved 
by the Chairman. Correction was made by Obodoechina regarding the amount of money in the bank, 
which was not recorded in the minutes. The Chairman instructed that the correct figure, which was 
€680.30, should be included in the minutes of the current meeting.
  </p>

  <h5>Permanent Meeting Venue:</h5>
  <p>
  The Chairman stressed the significance of locating a permanent venue 
for our meetings. In light of this, he proposed a motion for dues increment to provide the association 
with the necessary funds to secure a permanent place for future meetings. <br/>Chucks expressed his agreement with the idea of securing a permanent venue but raised concerns about the potential financial burden it might impose on the members. He highlighted that this could potentially discourage participation.
<br/><br/> Nwadike on the other hand, welcomed the idea of securing a permanent venue for our 
meetings as it would relieve members of the financial burden of hosting meetings at their homes and managing domestic responsibilities, such as taking care of children, while trying to conduct the  meetings. He suggested that the venue could be rotational, allowing Derry, Letterkenny and Ballybofey to be 
considered for future meetings. 
<br/><br/>
He pointed out that adopting a rotational approach for the permanent 
venue would offer a variety of options and cater to the preferences of different members in terms of 
location and accessibility. He also pointed out that the flexibility would ensure that members from 
various areas could easily attend meetings and contribute to the association's activities. <br/>Furthermore, 
Ogidi directed a question to Francis regarding the possibility of achieving the group's quest to secure St. 
Mary's Parish Hall as their permanent venue, and whether it could be done at a more affordable rate. In 
response, Francis stated that it was indeed possible to negotiate for a cheaper rate for the venue. 
<br/><br/>
Ogidi 
further asked about the cost of the venue, to which Francis responded that he would find out, 
Emmanuel Okezie raised a question regarding the current financial responsibility of hosting the 
meetings, whether it falls on the group or individual members. The response was that individual 
members currently bear the cost. Taking this into consideration, Emmanuel expressed his support for 
the proposed motion to obtain a permanent venue. He emphasized that the group should take care of 
the venue expenses, while the host would only be responsible for providing refreshments. 
<br/><br/>Everyone 
expressed agreement with Emmanuel's viewpoint. The Chairman concluded by stating that before 
proceeding with any further plans, it is crucial to confirm the availability of the desired venue. Once this 
is established, other arrangements can be made accordingly
  </p>

   <h5> Family Summer fun day</h5>
   <p>
   during the meeting, the chairman raised the topic of a 
summer family outing and sought on the member’s opinions on the whether they were 
interested in organizing such an event. Nwadike expressed the view that it might not be 
necessary to have a summer outing as it would involve significance costs and potentially impose 
a financial burden on the members. 
<br/><br/>
Emmaneul, on the other hand, considered the idea of the summer event to be a strategic one. He emphasized that it was the previous summer outing 
that initially attraced him to the group. Furthermore, he believed that such an event had a way of bringing family members together. Obinna also saw the summer outing as a goal as it would 
provide another opportunity for the group to fulfil their objective of community integration. <br/><br/>
In the end, all the members expressed their support for organizing a summer family outing. However, Chucks strongly emphasized the importance of payment for the event. He raised concerns about the previous event where some members had not paid their contributions, and he warned that such a 
situation should be avoided this time. In response, the Chairman assured the members that the issue of payment would be taken seriously and properly addressed. <br/>He reassured the house that they would prevent a recurrence of the discouraging situation experienced in the past and ensure that all members fulfil their payment obligations for the event. To further encourage timely payments, a visitor named 
Jude Instantly pledged to donate £50 in support of the summer event. Hopefully, his generous 
contribution would add enthusiasm and motivated other members to contribute as well.
   </p>
   <h5>Bank account signatories</h5>
   <p>
   The members agreed to select Obinna, Chucks, and Emeka as the 
authorized signatories for the association's bank account. This decision was made to ensure proper 
financial management and accountability
   </p>
   <h5>Membership status</h5>
   <p>
   our brother Joseph Opara registered as a new member during the 
meeting. The members warmly welcomed him into the association, expressing their excitement to have 
him on board. As part of the registration process, a registration fee of 15 euros was collected from 
Joseph. Regarding Billy, no response has been received from him regarding the letter we sent 
concerning his inactiveness. 
<br/><br/>It was agreed to send a follow-up letter informing him that, based on Article 
14, Section II -h of our constitution, he has ceased to be a member of ICANWI.
Chidi has been mandated to provide a brief update on his current position through a few lines on the 
WhatsApp group, informing everyone about his relocation to Limerick. Similarly, Chidozie is also 
requested to do the same in order to confirm his membership position within the group.
   </p>
   <h5>Website</h5>
   <p>
   The Chairman officially announced the completion of the group's website and 
provided an overview of the various stages involved in its development. To give members an 
idea of its appearance, Francis proceeded to demonstrate the website on the screen. The 
Chairman assured the members that the website would be ready and launched soon. However, 
he also requested Francis to be considerate with the charges involved when the time comes
   </p>
  <h5> Any Other Business</h5>
    <p>
    Brother Emmanuel shared with the association that he would be relocating 
from Derry to Magherafelt in May 2023. The group members expressed their understanding and 
conveyed their heartfelt good wishes to him- sincerely hoping for a smooth transition as he moved to 
his new location. 
Francis extended an invitation to the association for his children's confirmation and communion, 
scheduled for May 6th, 2023. Details regarding the exact time and venue will be provided at a later date. 
A visitor introduced himself as Mr. Ifeanyi Ejiofor from Enugu state. He attended the meeting as an 
observer. Mr. Ejiofor informed the members that he is currently pursuing an MSc degree at Ulster 
University, Magee.

    </p>
 
   <h5>Finance</h5>
   <p>
   Total of 105 euros was collected and handed over to the treasurer. This amount included 15 
euros for registration fees and 90 euros for membership dues. Ike moved a motion for adjournment, 
which was seconded by Obodoechina. The next meeting was scheduled to take place on the 21st of May 
at Elder Chijioke's residence
</p>



</main>
</div>
  )
}
export default April2023Meeting



          


         


            
           
         