import React from 'react'
import TopMenu from '../Header/DropdownContainer/TopMenu'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'
import nkwobi from '../image/nqwobi.png'
import salt from '../image/nkwobImage/salt.jpg'
import onions from '../image/nkwobImage/onions.jpeg'
import oil from '../image/nkwobImage/oil.jpg'
import ugba from '../image/nkwobImage/ugba.jpeg'
import utazi from '../image/nkwobImage/utazi.jpeg'
import potash from '../image/nkwobImage/potash.jpeg'
import ehu from '../image/nkwobImage/ehu.jpeg'
import crayfish from '../image/nkwobImage/crayfish.jpeg'
import cowleg from '../image/nkwobImage/cowLeg.jpeg'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-bootstrap'



const Nkwobi= () => {
  return (
    <>
    <Helmet>
   <title>The Delightful Delicacy: Nkwobi - A Culinary Gem of Igbo Cuisine"</title>
   <meta name = "description" content="Understanding certain concepts can serve as a guide to self-discovery. For example, how food influences and reflects cultural identity and heritage is one such concept"/>
   <link rel="canonical" href="Nkwobi"/>
    </Helmet>
    {<TopMenu/>}
   <NKWOBI className='container'>
      <header className="nkwobi-header">
        <h1 className='nkwobi-title'>The Delightful Delicacy: Nkwobi - A Culinary Gem of Igbo Cuisine</h1>
      </header>
      <section className="hero">
        <img src={nkwobi} alt="Nkwobi" />
      </section>
      <section className="description">
        <h2>About Nkwobi</h2>
        <p>
        Nkwobi is a traditional dish that originates from Igboland, Nigeria. It is commonly enjoyed as a leisurely meal, often after a specific event or a day's work, and it is known for its scrumptious taste. Originally, the dish was prepared using bush meat such as squirrels, rabbits, and antelopes, but today, it is made with cow meat, especially leg and tail, cooked in a spicy palm oil sauce and garnished with scent leaves. In the past, Nkwobi was a speciality in most bars and local restaurants in Igboland.
        </p>
        
        <p>
            However, over time, it gained popularity across Nigeria, and many lovers of the dish know little about its origin. Despite this, Nkwobi remains a cherished and delicious meal that is traditionally served in mini mortars or wooden plates, giving it a distinctive appearance. While Nkwobi can be enjoyed with any chilled drink of choice, fresh raffia palm wine remains the original and best accompaniment to this mouth-watering delicacy. As your food doctor, I highly recommend this rich and tasty meal for you and your loved ones 
        </p>
        {/* Add additional information about Nkwobi */}
      </section>
      <section className="preparation">
        <h2>How to Prepare Nkwobi</h2>
        <div className="ingredient-grid">
          <div className="ingredients">
            <h3>Ingredients</h3>
            <ul>
              <li>Cow foot or cow meat (leg or tail)</li>
              <li>Salt (to taste)</li>
              <li>Onions (sliced)</li>
              <li>Ugba (ukpaka)</li>
              <li>Seasoning cubes</li>
              <li>Edible potash</li>
              <li>pepper</li>
              <li>few seeds of ehu (calabash nutmeg)</li>
              <li>Utazi leaves (for garnishing)</li>
              <li>ground crayfish</li>
              <li>Palm oil</li>
            </ul>
          </div>
          <div className="ingredient-images">
            <img src="col leg" alt="Onions" />
            <img src="seasoning-cubes-image.jpg" alt="Seasoning Cubes" />
            <img src="salt-image.jpg" alt="Salt" />
            <img src="potash-image.jpg" alt="Edible Potash" />
            <img src="crayfish" alt="crayfis" />
            <img src="ehu" alt="ehu" />
            <img src="pepper" alt="pepper" />
            <img src="utazi" alt="utazi" />
            <img src="onions" alt="onions" />
            <img src="ukapka" alt="ukpaka"/>
            <img src="palm-oil-image.jpg" alt="Palm Oil" />
          </div>
        </div>
     <p>
     To prepare nkwobi, start by parboiling the meat with onions, two cubes of maggi, and salt until it is soft and easy to chew. This usually takes around 50-60 minutes. While the meat is cooking, slice some onions and utazi and set them aside on a plate. You can either add them to the pot during the cooking process or use them for decorative purposes later on. <br/>
     To prepare the ugba (ukpaka), boil it in a small amount of water for 2-3 minutes to eliminate any fermenting bacteria. Note that ugba is processed with the help of a harmless bacteria. Once the meat is almost dry, check the water level to ensure the consistency is not too watery. Stir the meat in the pot to prevent it from burning and cut it into small pieces for easier serving. <br/>
     Next, dissolve the potash in about 250ml (one cup) of water and filter about half a cup into the pot. Add half a cup of palm oil and stir until you obtain a thick yellow paste known as ncha. Then add the boiled ugba, a pinch of salt, a stock cube, and the pounded mixture of utazi leaves, ehu, and peppers. Also, add two spoons of ground crayfish, and stir well before adding the meat. If the meat is too hot, let it cool for 20-30 minutes to avoid dissolving the ncha and turning it reddish.<br/>

     The utazi leaves are necessary to add a slightly bitter taste, which is an integral part of nkwobi. You can pound some and slice some for garnishing. Nkwoi is typically served with boiled white yam, so it's a good idea to have extra sauce. Alternatively, it can be served in a wooden hollow bowl as a light meal. To summarize the steps: season and cook the meat, prepare the ugba and sliced onions and utazi, dissolve the potash and palm oil to make ncha, add the spices and ground crayfish, stir in the meat, and serve with yam or in a wooden bowl. Enjoy!<br/> If you want to enjoy locally prepared Nkwobi that offers a unique culinary experience centered around home-grown African vegetables and spices, click the link below. 
     <p><Link to= ''>Sandy's Kitchen | Where Delicious Meets Healthy </Link></p>

     </p>
      </section>
      </NKWOBI>
    </>
    
  );
};


export default Nkwobi
const NKWOBI = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
position: relative;
top: 6rem;
max-width: 80%;
width:auto;
margin: 0 auto 4rem;
padding: 3rem;
background: ivory;
min-height:100vh;
height:auto;
color:#333;
.nkwobi-header {
  text-align: center;
  padding: 20px;
  min-width:100%;
  max-width:100%;
  display:flex;
justify-content:center;
align-items:center;
background: yellow !important;
margin: auto !important;

 
}
.nkwobi-title{
   color:#333 !important;
   font-weight: bold;
   font-family:Verdana, Geneva, Tahoma, sans-serif;
   min-width:100%;
  max-width:100%

  }

.hero img {
  width: 100%;
  max-height: 450px;
}

.description{
    position:relative;
  padding: 20px;
  h2{ color:#333;} 
  p{
    color:#333;
    font-size:18px !important;
    line-height:5vh !important;
  }
 
}

.preparation {
    position:relative;
    padding: 20px;
    h2{ text-align: center;
    min-width:100%;
    color:#333;
    position:relative;
    top:-2rem !important;
    }
    p{
    font-size:18px !important;
    line-height:5vh !important;
    }
  }
  

.ingredient-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  gap: 20px;
  & .ingredients{
   h3{
    color:#333 !important;
   }
   ul>li{
    position:relative;
    left:-2rem;
  color: #333 !important;
   }
  }
}

.ingredient-images img {
  width: 100%;
}

@media (max-width: 768px) {
  .ingredient-grid {
    grid-template-columns: 1fr;
  }
  .description,
  .preparation{
    padding: 5px !important;
    min-width: 90vw !important;
    margin: auto !important;
    left:-4rem !important;
    &:not(.description){
     top:2rem !important;
    }
  }
  .nkwobi-header{
    position:relative;
    min-width:100vw !important;
    max-width:100vw !important;
    margin:0 auto !important;
    padding:20 !important;
    left:-4rem !important;
    .nkwobi-title{
      margin:auto;
      justify-self:flex-start;
      min-width:100vw !important;
    max-width:100vw !important;
    padding:0 3rem !important;
    }

  }
  margin-bottom:4rem !important;
  padding-bottom: 6rem !important;
  
}


`