import React from 'react'
import Menu from '../../../Header/DropdownContainer/Menu'
import styled from 'styled-components'
import video1 from '../../../video/relay1.mp4'
import charity from '../../../image/charity-relay.jpeg'
import charity1 from '../../../image/charity1.jpg'
import charity2 from '../../../image/support.jpg'
import { useRef } from 'react'
import { Helmet } from 'react-helmet-async'

const Charity = () => {
  const videoRef= useRef();
  const setPlayBack = () => {
    videoRef.current.playbackRate = 0.2;
  };


  return (
    <>
     <Helmet>
   <title>Charity</title>
   <meta name = "description" content="ICANWI assists those in need and their charitable program has made a positive impact on the lives of those they serve"/>
   <link rel="canonical" href="/Acheivement" />
    </Helmet>
    {<Menu/>}

       <CHARITY className='p-5 m-auto relative top-0 container'>

      <header className="header">
        <video className="video-background" autoPlay loop muted  ref={videoRef}  onCanPlay={() => setPlayBack()}>
          <source src={video1} type="video/mp4" />
          <source src="path/to/video.webm" type="video/webm" />
          Your browser does not support the video tag.
        </video>
        <div className="titles"><h1>Supporting Humanity is Charitable</h1></div>
      </header>
      <main>
        <div className="text-page">
          <h2>Charity</h2>
          <p>
          Charity involves providing support and assistance to those in need, typically through donations or volunteering. It is a form of giving back to society and helping to address societal challenges. Also to assist and provide support to individuals or groups facing challenges or difficulties. They may involve financial, emotional support. The Igbo Cultural Association is an Igbo cultural group that is based in Northwest Ireland.  As part of its primary mission, it promotes unity in the community and assists those in need. The group has a strong commitment to charity, and their donations have been directed towards various organizations that support people in need, such as the Irish Wheelchair, the Irish Cancer Society, Eyecare in Africa, and among others. Their charitable program has made a positive impact on the lives of those they serve

          </p>
        </div>
        <div className="grid-container">
          <div className="grid-item item1" >
          <img src={charity} alt="image.title" />
          </div>

          <div className="grid-item item2" >
          <img src={charity1} alt="image.title" />
          </div>

          <div className="grid-item item3" >
          <img src={charity2} alt="image.title" />
        </div>
        </div>
      </main>
      </CHARITY>

    </>
  )
}

export default Charity
const CHARITY = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .header {
  position: relative;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  top:-3rem !important;

}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}



h1 {
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
  color:#050000 !important;
}

main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.text-page {
  max-width: 800px;
  margin-bottom: 2rem;
  text-align: left;

}

.text-page h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color:#000;
}

.text-page p {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #555;
  font-size:18px;
  line-height:5vh;
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;

}

.grid-item {
  position: relative;
  min-width: 300px;
  min-height: 200px;
  max-width: 300px;
  max-height: 200px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  img{
    min-width:100%;
    max-width:100%;
    min-height:100%;
    max-height:100%;
  }
}

.grid-item:hover {
  transform: scale(1.1);
}

.grid-item-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
}

@media screen and (max-width: 768px) {


  h1 {
   display:none !important;
  }

  .text-page h2 {
    font-size: 2rem !important;
  }

  .text-page {
    min-width:100vw;
    text-align: center !important;
    padding:0 2rem !important;

  p {
    font-size: 19px;
  
  }
  }
  .grid-container {
    max-width: 90vw;
  }

  .grid-item {
    width: calc(33.33% - 0.5rem);
    height: 150px;
  }
}

`