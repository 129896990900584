

import React from 'react';
import Navbars from '../../MinutesPage/Navbars'
import {Container, Navbar, Col, Row } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';


function August2023Meeting() {
  return (
    <div className="Article-cover">
    {<Navbars/>}

   {/*    page body starts here */}

   <main className='main'>
     
   <h2> Minutes for August</h2>
   <p style={{fontSize:'1.1rem'}}>
   <b>Date:</b> 13/08/2023
   <br/>
   <strong>Members Present:</strong> <span className='m-present'>.
   Kingsley Agoha, Francis, Charles Obodoechina, Emeka Onwuzo, Chijioke, Austin, Joseph and Emmaneul
   </span>
   <br/><br/>
   <strong>Members Absent:</strong> <span className='m-abscent'>
   Chidi Usanga, Soki, Chidebere, Ugo Nwankwo, Ike Nwadike, Innocent Ike, Edozie, Peter and Kingsley Iqwe
   </span>
   <strong>Excuse:</strong> <span className='m-abscent'>
   Soki- not in town, Ugo Nwankwo - not in town, Ike Nwadike - not in town, Innocent Ike - busy with school project.
   </span>
   </p>

<h5>Topics Discussed</h5>


<p className='text-dark'>
•	Previous Meeting Review<br/>
•	Last Family Summer Review<br/>
•	Summer Party-Finacial Report<br/>
•	Our Website<br/>
•	ICANWI Youth Club<br/>
•Any other Business <br/>
•	Finance<br/>

</p>



   <h5>Previous Meeting Review</h5>
   <p>
   


   The meeting commenced promptly at 5:30pm PM. Francis led an open prayer, after which he read the minutes of the previous meeting. This was subsequently approved by Chuks, after making an observation that the update on Billy’s membership was not well detailed. 
On that note, the chairman announced to the association that Billy had ceased to be a member of the association due to a violation of Article 14, Section 11(h) of our constitution. Upon further review of the minutes, Emmanuel questioned the decision to shift his position to Emeka as the provost without his consent. Obinna clarified that Emeka was appointed temporarily to fill in for the provost's absence.








  </p>


   <h5>	Last Family Summer Review</h5>
   <p>
   The chairman expressed his appreciation to the members for their financial commitment and overall support, which contributed significantly to the apparent success of the event. He then presented a summary of the event's account statement on the screen, revealing that both members and the association were not in any debt or owed any money. The analysis was accepted unanimously by all present. Considering the role that the children played in the event, Emmanuel suggested organizing more educational activities instead of just dancing only. 
   <br/><br/>Chuks thought the suggestion was highly strategic, emphasizing activities like question-and-answer sessions in Igbo could be ideal. Joseph added that offering prizes as incentives could also boost motivation. Emmanuel also addressed the issue of the venue, emphasizing the importance of the association securing a dedicated location for such events instead of frequently inconveniencing Emeka and his family. The idea was supported by everyone

  </p>


  <h5>Summer Party-Finacial Report:</h5> 
  <p>
  After reviewing the financial records of the previous executive, the chairman expressed gratitude to the officers for their excellent work, as they not only managed to avoid incurring debt for the association but also left a surplus. Building on this, Charly emphasized the importance of improving transparency and accountability in various aspects, including bank operations, member dues and levies, quarterly account statements, and invoicing procedures. On that note, the recently elected treasurer, Obinna, pledged to provide a comprehensive current account report that would offer deeper insights into the association's current financial state.
  </p>

  <h5>	Our Website:</h5>
  <p>
  The chairman briefly emphasized the importance of our website and encouraged everyone to visit it, as well as our social media platforms. Charly expressed appreciation for the website but raised questions about its maintenance costs. He inquired from Francis about the cost of maintenance, to which Francis responded that he would accept any amount that the association found reasonable and affordable.<br/><br/>
  In response, Charly suggested launching the website during the Christmas part event and using the funds generated from the event to pay Francis. Chuks also praised the website and proposed an annual payment model based on the frequency of maintenance. He pointed out that longer-term domain subscriptions, such as 3 or 5 years, are more cost-effective. After thorough discussion, it was decided that the executive committee would further deliberate on the website's maintenance and payment structure.
  </p>

  <h5>	ICANWI Youth Club:</h5>
  <p>
  The chairman introduced the concept of organizing a youth club aimed at engaging children in activities that promote learning, personal growth, and the exploration of their potential. Charles and Emeka expressed concerns regarding logistics, specifically focusing on issues related to timing, venue, and the availability of parents who would bring their children. Charly pointed that timing might conflict with parents' work schedules. Emeka also pointed out  that the distance to the venue and it's cost could discourage participation. He suggested that youth programs should be organized on our regular meeting days instead. In the end, the idea was accepted, but the specific implementation options would be discussed in subsequent meetings.
  </p>
  <h5>	Any Other Business:</h5>
  <p>
  Following the recently concluded summer event, the chairman expressed gratitude to Emeka and Francis for initiating the idea of appreciating the hosts and assured them that their expenses would be reimbursed. The discussion then shifted to the upcoming Christmas party, with a potential date set for December 10th and the venue chosen as Lisneal in Derry.<br/><br/>
Another discussion that came up was about future events: the Anniversary and Iri-ji festival. 
Considering that the association is 13 years old, Francis believed it was strategically important to celebrate the anniversary. Charly and Emmanuel supported both ideas. Furthermore, the membership of Jerry Obi Jr was reviewed and subsequently terminated due to his lack of interest and commitment.

  </p>


  

   <h5>Finance</h5>
   <p>
   A total of €100 euros were collected and handed over to the treasurer. €90 for dues and €10 for summer levi. Emeka move for Adjournment.  The next meeting was scheduled on the 10th of Sept at the chairman’s house in Derry. The closing prayer was led by ......
  </p>

   <Container>
         <Row className='d-flex justify-content-center align-items-center'>
           <strong><h4 className='text-danger p-2 px-0 sumary'>
             Summary of the Dues</h4>
           </strong>
           </Row>

           <div className='tab' style={{minWidth:'400px',maxWidth:'400px'}}>
   
         <Table striped bordered hover variant="dark">
         <thead>
           <tr>
           <th>NAMES</th>
           <th>DEUS</th>
           
      
           </tr>
         </thead>

         <tbody>
         <tr>
           <td>Emeka</td>
           <td>€10 </td>
        </tr>
         <tr>
           <td>Soki</td>
           <td>paid in advance</td>
        </tr>
         
         <tr>
           <td>Joseph</td>
           <td>€10 </td>
        </tr>
        <tr>
           <td>Ugo</td>
           <td>not paid</td>
        </tr>
         <tr>
           <td>Austin</td>
           <td>€10 </td>
        </tr>
         <tr>
           <td>Chuks</td>
           <td>€10 </td>
        </tr>
         <tr>
           <td>Kingsley</td>
           <td>€10 </td>
        </tr>
      
        
         <tr>
           <td>Francis</td>
           <td>€20 - May/June </td>
        </tr>

         <tr>
           <td>Charley</td>
           <td>€10 </td>
        </tr>
         <tr>
           <td>Emmanuel</td>
           <td>not paid </td>
        </tr>
         <tr>
           <td>Kingsley Iqwe</td>
           <td>not-paid</td>
        </tr>
         <tr>
           <td>Innocent Ike</td>
           <td>not paid</td>
        </tr>
         <tr>
           <td>Peter</td>
           <td>€10</td>
        </tr>
        </tbody>
         </Table> 
         </div>
       </Container>

 </main>
</div>
);
}

export default August2023Meeting;


         


           
              
      