import React from 'react'
import Menu from '../../../Header/DropdownContainer/Menu'
import styled from 'styled-components'
import video1 from '../../../video/relay1.mp4'
import charity from '../../../image/charity-relay.jpeg'
import black1 from '../../../image/blacklife.jpg'
import equal from '../../../image/equality.png'
import black2 from '../../../image/blacklife1.jpeg'
import { useRef } from 'react'

const Equality = () => {
  const videoRef= useRef();
  const setPlayBack = () => {
    videoRef.current.playbackRate = 0.2;
  };


  return (
    <>
    {<Menu/>}
       <EQUALITY className='p-5 m-auto relative top-0 container'>

       <header style={{ backgroundImage: `url(${equal})` }}>
       <div className="titles"><h1>Respect is Devine</h1></div>
      </header>
    
      <main>
        <div className="text-page">
          <h2>Equality and Diversity</h2>
          <p>
          Equality and diversity are related concepts that involve treating everyone equally, irrespective of their gender, race, ethnicity, religion, or other factors. It entails recognizing and valuing differences and ensuring that everyone has access to the same opportunities and benefits. The Igbo Cultural Association Northwest Ireland(ICANWI) believes in equality and diversity, which is reflected in their work. They have worked alongside different groups to fight against racial discrimination and ethnic/cultural abuses. Their commitment to equality and diversity is also demonstrated through their charitable program, where their donations have been directed towards various organizations such as the Irish Wheelchair, the Irish Cancer Society,  Eyecare in Africa, and others. By recognizing and valuing differences, they create a more inclusive and equitable community.

          </p>
        </div>
        <div className="grid-container">
          <div className="grid-item item1" >
          <img src={black1} alt="image.title" />
          </div>

          <div className="grid-item item2" >
          <img src={black2} alt="image.title" />
          </div>

      
        </div>
      </main>
      </EQUALITY>

    </>
  )
}

export default Equality

const EQUALITY = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  header {
  position:relative;
margin-top:1rem;
 padding: 2rem 0;
  color:white;
  text-align: center;
  background-position:45% 42%;
  background-size: cover;
  height: 180px;
  position: relative;
}

header::befor {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

header h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-shadow: 2px 2px #000;
}

main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.text-page {
  max-width: 800px;
  margin-bottom: 2rem;
  text-align: left;

}

.text-page h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color:#000;
}

.text-page p {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #555;
  font-size:18px;
  line-height:5vh;
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
  max-width:80%;

}

.grid-item {
  position: relative;
  min-width: 400px;
  min-height: 300px;

  background-size: cover;
  background-position: center;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  img{
    min-width:100%;
    max-width:100%;
    min-height:100%;
    max-height:100%;
  }
}

.grid-item:hover {
  transform: scale(1.1);
}

.grid-item-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  

  h1 {
    font-size:1.5rem  !important;
  }

  .text-page h2 {
    font-size: 2rem !important;
  }

  .text-page {
    min-width:100vw;
    text-align: center !important;
    padding:0 2rem !important;

  p {
    font-size: 19px;
  
  }
  }
  .grid-container {
    max-width: 90vw;
  }

  .grid-item {
    width: calc(33.33% - 0.5rem);
    height: 150px;
  }
}

`