  
  import React, {useRef, useState} from 'react'
  import { useNavigate} from 'react-router-dom';
  import { useLocation } from 'react-router';
  import styled from 'styled-components';
  import Modals from './Modals';
  import { useAuth } from '../../../Auth';
  import hand from '../../image/hand.png'
  import '../../../CSS/UserLogin.scss'
  import TopMenu from './TopMenu';
  import { NavLink } from 'react-bootstrap';
  import ResetPassword from '../ResetPassword';


  let update = {
    value1:"oOOPS! Something has gone wrong",
    value2:"Username may be incorrect",
    value3:"Password may be incorrect",
    user:"eze@gmail.com",
    passwords:"oji-igbo",
    values:'Please login to your account',
    usertext:'Your confidentiality is our priority',
    UserEmail: '  User-Email',
    pw: 'Password'
    }
  
    const SignIn = () => {
    const auth = useAuth();
    const locations = useLocation();
    const redirect = locations.state?.path || '/PrivatePage'
    let refs = useRef(null);
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [value , setValue] = useState(update.values);
    const [value2 , setValue2] = useState("");
    const [value3 , setValue3] = useState("");
    const [pw, setpw] = useState(update.pw);
    const [userEmail , setuserEmail] = useState(update.UserEmail);
    const [model , setModel] = useState(false);

    let navigate = useNavigate();

       const checkStatus = () => {
       if(user===update.user && password===update.passwords){
        auth.SignIn(user)
        navigate(redirect, {replace: true})
       
       }
       else{
        setValue(update.value1);
        setValue2(update.value2);
        setValue3(update.value3);
        setpw("");
        setuserEmail("");
        
      }
    }; 

   const handleLogin = ()=>{
   checkStatus();
   }

  return (
   <>
{ < TopMenu className='fixed top-0' />}  

  <SignForm className="container-fluid signForm-cover relative top-6">
   
        <div className=" rounded-3 text-black signIn-card">
          <div className="row  signFormRow">
            <div className="col-lg-12 signFormCol">
              <div className=" p-md-5 mx-md-4 form-bodys relative">
                <div className="signFormTitle-img relative top-10">
                  <img src={hand}
                    style={{width: "80px"}} alt="logo"/>
                  <h2 className="mt-3 mb-5 pb-1">We are a Charitable Group</h2>
                </div>

                <form className='form-input-body ' onSubmit={(e)=>e.preventDefault()}>
                  <h5 className='text-light'>{value}</h5>

                  <div className="form-outline mb-3 pb-6 relative ">
                    <input type="email" id="form2Example11 " className="form-control email "
                      placeholder="Enter your email address" required='required'
                      onChange={(e)=>setUser(e.target.value)} 
                      ref={refs} 
                      />
                      <label className="form-label text-light pt-1" for="form2Example11" style={{fontFamily:'sans-serif', fontWeight:'600'}}>
                      {userEmail}<span className="text-success mx-2 text-xs" 
                      > 
                      <span style={{position:'relative', top:'0', fontFamily:'sans-serif', fontSize:'16px'}}>{value2}</span></span>
                      </label>
                  </div>

                  <div className="form-outline mb-3">
                    <input type="password" id="form2Example22 " 
                    placeholder='include number and special character' required='required'
                    className="form-control sign-input password" 
                    onChange={(e)=>setPassword(e.target.value)}/>
                     <label className="form-label text-light 
                     pt-1" for="form2Example22" style={{fontFamily:'sans-serif', fontWeight:'600'}}>
                      {pw}<span className="text-success mx-2 text-xs"><span style={{position:'relative', top:'0', fontFamily:'sans-serif', fontSize:'16px'}}>{value3}</span></span>
                      </label>
                  </div>


                  <div className='ID'>
                  <div className="text-center pt-1 mb-3 pb-1 form-login-btn">
                    <button className=
                    "  gradient-custom-signIn mb-3 me-10" 
                    type="submit" onClick={handleLogin}>
                      Login
                    </button>
                   
                    <p className="text-muted no-password " href="/ResetPassword">
                      <span className='reset'>
                      <ResetPassword/>
                    </span>
                 
                    </p>
                  </div>
                  <div className="d-flex form-signUp-btn   align-items-center justify-content-center pb-3">
                    <p className ="mb-0  text-light no-account text-muted relative top-3 ">
                    Have no account?
                    </p>
                    <p  className="text-muted  text-light register-component ">
                   
                <Modals/>
                 
                    </p>
                  </div>
                  </div>
                </form>

              </div>
            </div>
          
          </div>
        </div>
      </SignForm>

</>
  );
  }

export default SignIn
 const SignForm = styled.div`
  .reset{
    position: relative;
    left:-15px !important;
    @media(max-width:420px){
      left:25px !important;
    }
  }

 `