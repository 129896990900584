
import React from 'react';
import '../../CSS/Minutes.scss';
import MinutesMenu from './MinutesMenu'



const meetings = [
  { id: 1, date: 'ICANW Constitution', url: 'PrivatePage/Constitution'},
  { id: 2, date: 'Finance', url: 'PrivatePage/Finance'},
  { id: 3, date: 'Meeting Hosting Rota', url: 'PrivatePage/RotaTable'},
  { id: 4, date: 'Feb-2023-02-05 Meeting', url: 'PrivatePage/Feb2023Meeting' },
  { id: 5, date: 'Mar-2023-03-12 Meeting' , url: 'PrivatePage/March2023Meeting' },
  { id: 6, date: 'April-2023-04-16 Meeting', url: 'PrivatePage/April2023Meeting' },
  { id: 7, date: 'May-2023-05-21 Meeting', url: 'PrivatePage/May2023Meeting'},
  { id: 8, date: 'June-2023-06-11 Meeting', url: 'PrivatePage/June2023Meeting'},
  { id: 9, date: 'Aug-2023-08-13 Meeting', url: 'PrivatePage/August2023Meeting'},
  { id: 10, date: 'Sep-2023-09-10 Meeting', url: 'PrivatePage/Sept2023Meeting'},
  { id: 11, date: 'Feb-2022-02-13 Meeting', url: 'PrivatePage/Feb2022Meeting' },
  { id: 12, date: 'Mar-2022-03-13 Meeting' , url: 'PrivatePage/March2022Meeting' },
  { id: 13, date: 'April-2022-04-16 Meeting', url: 'PrivatePage/April2022Meeting' },
  { id: 14, date: 'May-2022-05-08 Meeting', url: 'PrivatePage/May2022meeting' },
  { id: 15, date: 'June-2022-06-12 Meeting', url: 'PrivatePage/June2022Meeting' },
  { id: 16, date: 'Sep-2022-09-18 Meeting', url: 'PrivatePage/Sep2022Meeting' },
  { id: 17, date: 'Oct-2022-09-10 Meeting', url: 'PrivatePage/Oct2022Meeting' },
  { id: 18, date: 'Nov-2022-19-11 Meeting', url: 'PrivatePage/Nov2022Meeting' },
  { id: 19, date: 'Oct-2019-02-13 Meeting', url: 'PrivatePage/Oct2019Meeting' },

];



function MeetingButton({ date, url }) {
  
  return (
    <a className="meeting-button" href={url}>
      {date}
    </a>
  );
} 

function Prev() {
  
  return (
    <>
    {<MinutesMenu/>}
    <div className="app relative top-0" style={{marginTop:'-2rem'}}>
      <h1 className="header">Previous Meeting Minutes</h1>
      <div className="meeting-buttons">
        {meetings.map((meeting) => (
          <MeetingButton key={meeting.id} date={meeting.date} url={meeting.url}/>
        ))}
      </div>
    </div>
    </>
  );
}

export default Prev;
