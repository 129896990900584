import React from 'react'
import Menu from '../../../Header/DropdownContainer/Menu'
import { Outlet } from 'react-router-dom';
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'


const LanguageDetail = () => {
  return (
    <>
          <Helmet>
   <title>Importance of the Mother Tougue</title>
   <meta name = "description" content=" There are several reasons why the Igbo language may be less spoken globally and her are a few possible reasons"/>
   <link rel="canonical" href="/LanguageDetail" />
    </Helmet>
    {<Menu/>}
        <MISION className='container py-5 relative top-6'>
            <h1 className='p-3 text-center text-dark' style={{fontWeight:'bold'}}>
            Why Igbo language is less spoken globally
                </h1>
          <section className='p-3 text-left' > 
          <p>
          There are several reasons why the Igbo language may be less spoken globally. Here are a few possible reasons:<br/>
          <strong>Colonization and Language Policies:</strong> During colonial times, European powers enforced their own languages as the official languages of their colonies, often at the expense of indigenous languages like Igbo. Even after colonialism ended, many countries continued to use the language policies of their former colonizers, which further marginalized indigenous languages
          </p>
          <p>

        <strong>Migration and Assimilation:</strong> Many Igbo people have migrated to other parts of the world, where they have adopted the languages of their new homes. This has contributed to the decline of Igbo language use, especially among younger generations who are more likely to be born and raised in countries where Igbo is not spoken.
      </p>
      <p>

        <strong>Education Policies: </strong>In many countries, education policies prioritize the teaching of official languages at the expense of indigenous languages like Igbo. This can lead to fewer opportunities for children to learn and use Igbo, further contributing to its decline.
   </p>
   <p>
        <strong>Globalization and Technology:</strong> The rise of globalization and digital technology has led to the dominance of certain languages, such as English, as the primary means of communication worldwide. This has made it more difficult for smaller languages like Igbo to gain widespread use and recognition.

          </p>
          </section>
          <section className='p-3 text-left'>
          <h3 className='p-3 text-left text-dark' style={{fontWeight:'bold'}}>
            
         Does learning the Igbo language  worth the effort?. Yes, and here are a few possible reasons
            
            </h3>

         
<p> 
<strong> Cultural Heritage: </strong> Learning the Igbo language can help people connect with the rich cultural heritage of the Igbo people. Igbo language is an integral part of the culture, and it reflects the unique history, customs, and values of the Igbo people.
</p>
<p> 
<strong>Personal Growth: </strong> Learning a new language can be a rewarding experience that can lead to personal growth. It can help individuals improve their cognitive abilities, boost their confidence, and broaden their perspectives.
</p>
<p> 
<strong>Professional Opportunities: </strong> For those who work in fields such as international business, diplomacy, or academia, knowing Igbo can be a valuable skill. It can also open up opportunities to work in Nigeria or with Igbo communities around the world.
</p>
<p> 
    <strong>Connection with Others: </strong> If a person has Igbo-speaking friends, family members, or colleagues, learning the language can help strengthen those relationships. It can also help bridge cultural divides and promote understanding between people from different backgrounds.
</p>
<p> 
<strong>Preservation of Language:</strong> Finally, learning the Igbo language can help preserve it for future generations. As mentioned earlier, Igbo language is at risk of disappearing, so learning it can help ensure that it continues to be spoken and valued for years to come

</p>


 </section>
</MISION>
    
    </>

  )
}

export default LanguageDetail;

const MISION = styled.div`
max-width:80%;
padding: 0 4rem;
@media(max-width:992px){
    padding:0 !important;
}
h1, h3{
    min-width:100%;
}
p{
    color:#333;
    font-family:'Ariel', sans-serif;
    line-height: 5vh;
    font-size:18px;
}
@media(max-width:786px){
    h1{
        font-size:1.2rem !important;
        color:green !important;
    }
    p{
    line-height: 4.5vh !important;
    font-size:19px !important;
    }
   
    }

`