

import React from 'react';
import Navbars from '../../MinutesPage/Navbars'
import {Container, Navbar, Col, Row } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';
import styled from 'styled-components'


function Oct2022Meeting() {
  return (
    <div className="Article-cover">
    {<Navbars/>}

   {/*    page body starts here */}

   <main className='main'>
     
   <h2> Minutes for September</h2>
   <p style={{fontSize:'1.1rem'}}>
   <b>Date:</b> 18/09/2022
      <br/><br/>
   <strong>Members Present:</strong> <span className='m-present'>.
   Kingsley Agoha, Austine Okeagu, Charles Obodoechina, Francis Udoku, Emeka Onwunzo, Dozie, Chidi Usanga and Ugo Nwankwo
   </span>
   <br/><br/>
   <strong>Members Absent:</strong> <span className='m-abscent'>
   Chuks Ogujawa, Billy and Chidiebere
   </span>
   </p>
   <h5>Proceedings</h5>
   <p>
   Minutes were taken by Charles Obodoechina and the open prayer was led by Pastor Gabriel.
  As usual, the chairman welcomed everyone.
  </p>

  <h5>Agenda</h5>
   <p>
   <UL className=''>
              <li>1. Relay4Life Update:</li>
              <li>2. Charity status:</li>
              <li>3.Banks/Finacial Update : </li>
              <li>3.End of the Year Party : </li>
    </UL>
  </p>

   <h5> Relay4Life Update</h5>
   <p>
   The chairman inquired about the relay for life event from Francis. Francis provided an update, stating that the organizers had expressed their gratitude and appreciation for our participation, and had sent a message conveying their thanks. They were pleased with the beautiful colors we displayed and our adherence to the rules, standards, and principles outlined for the event, which contributed to its success. He further explained the participation and the donations towards the event, including the certificate of appreciation awarded to ICANWI. The Chairman expressed his delight at everyone's support, especially Francis, who dedicated his time and resources to ensure that our participation was successful.
   </p>
  
   <h5> Charity status</h5>
   <p>
   Ugo conveyed his gratitude to all the participants of the relay for life event and emphasized the need to hasten the process of becoming a charitable organization. Kingsley acknowledged the validity of Ugo's point but questioned how members, occupied with their daily tasks, could manage the application process. Eventually, it was decided that the application form and constitution would be shared with the members for their perusal and feedback would be sought at the upcoming meeting.
   </p>

   <h5>Banks/Finacial Update :</h5>
   <p>
   During the meeting, the chairman presented a financial report which stated that we had €680 in the bank and €105 in cash. Despite being unable to hold a meeting in August, €125 that was raised during that month and could be attributed to our cash reserves. Regarding the bank signatories, Ugo agreed to collaborate with Chuks to resolve the issue and Francis offered to assist them, which was agreed upon by everyone.
   <span className='break'><br/></span>
  Kingsley expressed his gratitude to Ugo and Francis for volunteering, and also provided an explanation of how he handled the €50 that Chuks believed he was owed. He encouraged anyone who was unsure to consult the minutes from that period.
  The Chairman expressed appreciation to Kingsley for the clarification and apologized for any inconvenience caused to him. Additionally, Francis thanked Kingsley and Chuks for handling the matter with maturity.
    </p>

    <h5> End of the Year Party </h5>

   <p>
   The chairman inquired about the End of Year party and the plans that had been made for it. Francis stressed that it would be strategic to organize the party as a way of recognizing the return of Fr. Ohara, who had served our people in Igbo land for over four decades. It would also be an excellent opportunity to invite the the ambassador to our program. Kingsley supported the idea, saying that having the Nigerian ambassador as well as other local dignitaries would be a significant achievement. 
   <span className='break'><br/></span>
   Gabriel raised concerns about whether enough resources would be available to pursue the project, given the processes involved and the timing. He suggested that the date could be changed if necessary.  Kingsley recommended obtaining the cost estimate, including the venue, before the next meeting. Then, a decision could be made based on the estimate.It was accepted by all.
    </p> 
   
  
   
  
   <h5>Finance</h5>
   <p>
   €75 was collected for Deus and €30 for Registration, making a total of €105.
   </p>

   <Container>
              <Row className='d-flex justify-content-center align-items-center'>
                <strong><h4 className='text-danger p-2 sumary px-0'>
                  Summary of the Dues and Payement</h4>
                </strong>
                </Row>

                <div className='tab' style={{minWidth:'400px',maxWidth:'400px'}}>
        
              <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                <th>NAMES</th>
                <th>DEUS</th>
                <th>REGISTRATION</th>
           
                </tr>
              </thead>

              <tbody>
              <tr>
                <td>Gabriel</td>
                <td>€5 </td>
                <td>€15 </td>
             </tr>
             <tr>
                <td>Emmanuel</td>
                <td>€10 </td>
                <td>€15 </td>
         
             </tr>
              <tr>
                <td>Emeka</td>
                <td>€10 </td>
                
             </tr>
            
              <tr>
                <td>Chuks</td>
                <td>€10 </td>
          
             </tr>
              <tr>
                <td>Kingsley</td>
                <td>€10 </td>
             </tr>
              <tr>
                <td>Obinna</td>
                <td>€10 </td>
             </tr>
            
            
              <tr>
                <td>Francis</td>
                <td>€10 </td>
            
             </tr>
              <tr>
                <td>Charley</td>
                <td>€10 </td>
            
             </tr>
            
             </tbody>
             
              </Table> 
              </div>
            </Container>

</main>
</div>
  )
}
export default Oct2022Meeting

     const UL = styled.ul`
     li{
  margin-left:-2rem !important;
     }
     ` 







      
         
           