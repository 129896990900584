

import React from 'react';
import Navbars from '../../MinutesPage/Navbars'
import { Container, Navbar, Col, Row } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';


function Feb2022Meeting() {
  return (
    <div className="Article-cover">
      {<Navbars/>}

      {/*    page body starts here */}

      <main className='main'>

        <h2 className='text-dark'> Minutes for February</h2>
        <p style={{ fontSize: '1.1rem' }}>
      
          <b>Date:</b> 13/02/2022
          <br /><br />
          <strong>Members Present:</strong> <span className='m-present'>.
            Kingsley, Obodoechina, Francis, Emeka, Ugo, Chuks
          </span>
          <br />
          <strong>Members Abcent:</strong> <span className='m-abscent'>
            CJ, Billy, Eloka and Obinna
          </span>
        </p>

        <h5>Agenda</h5>


          <p class="d-flex flex-column mx-2" >

            <li class="d-flex align-items-center py-1">
              <span class="bullet bg-black mx-2"></span>
              Obi's Repatriation and Funeral Updates
            </li>
            <li class="d-flex align-items-center py-1">
              <span class="bullet bg-black mx-2"></span>
              Financial Statement / Repatriation Expenses
            </li>
            <li class="d-flex align-items-center py-1">
              <span class="bullet bg-black mx-2"></span>
              Stanley's Membership Review
            </li>
            <li class="d-flex align-items-center py-1">
              <span class="bullet bg-black mx-2"></span>
              Charitable Status review
            </li>
            <li class="d-flex align-items-center py-1">
              <span class="bullet bg-black mx-2"></span>
              St Patrick Event
            </li>

          </p>
        




        <h5>Proceedings</h5>
        <p>The Meeting was called to order at 5:50PM. Open prayer was said by Francis. Minutes of the last meeting were also taken by Francis and approved as corrected by Chuks
        </p>


        <h5>Obi's Repatriation and Funeral Updates</h5>
        <p>
          As the Chairman welcomed everyone to the meeting, he expressed gratitude for the extra effort and commitment that members put into repatriating Jerry Obi. He also asked if there was something that could be done differently in the future. Kinsley said that everything went according to plan
          praising the members for their commitment. Chuks also agreed that the repatriation event was a success - speaking highly of the successful fund raising and wake, both of which he viewed as great and encouraging achievements.
          <span className='break'><br /></span>
          However, he did note that members need improvement on the compliance with all agreements and condition relative to the conduct of any events such dressing code, dancing and other form of socializing, suggesting that chairman at the time should try to enforce such arrangements or agreements.Kinsley, Obinna, and Chuks suggested that the host should be responsible for hosting, but he can only provide whatever he has. The later was approved by a majority vote

        </p>


        <h5>Funeral Expenses / Repatriation Expenses:</h5>
        <p>
          Prior to the meeting, the Treasurer and Vice Chairman had presented their financial statements to the group via WhatsApp. All funds released and expenses made were documented and accounted for accordingly. The motion to accept the statements as presented was moved by Francis and supported by all
        </p>

        <h5>Stanley's Membership Review:</h5>
        <p>
          In accordance with our constitution, any member who leaves the group and subsequently wishes to re-join must submit a written request. As Stanley has not yet submitted his request, it was agreed  that his case should wait until he does so
        </p>

        <h5>Charitable Status review:</h5>
        <p>
          An application for charitable status was discussed, as well as issues regarding governance and standard requirements. A decision was reached to move forward with the application, where progress would be discussed at each meeting.
        </p>

        <h5>St Patrick Event</h5>
        <p>
          St. Patrick's event was genuinely discussed. However, considering the resources involved in the preparation and the amount of time and money it will take, it was decided to not participate this year. We look forward to coming back next year with style.
        </p>

        <h5>Any Other Business</h5>
        <p>
          Chariman suggested a condolence visit to Uche Alomu on a free will donation basis. Ugo also suggested a courtesy visit to the Nigerian Ambassador in Dublin. The suggestions were supported by everyone, but no date has been set yet
        </p>


        <h5>Finance</h5>
        <p>
          The sum of €60 was collected for dues. The €10 each was given to Chuks and Ugo as their part payment and  €40 was given to the treasurer. Also, $10 + €40 were collected for food and used to pay for it. The motion for adjournment was moved by Francis, seconded by Vc at 7:45pm.
          The next meeting is scheduled for Mar 13th, 2022.
          The closing prayer was said by Francis.
        </p>

        <Container>
          <Row className='d-flex justify-content-center align-items-center'>
            <strong><h4 className='text-danger p-2 px-0 sumary'>
              Summary of the Dues and Payement</h4>
            </strong>
          </Row>

          <div className='tab' style={{ minWidth: '400px', maxWidth: '400px' }}>

            <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>NAMES</th>
                  <th>DEUS</th>
                  <th>Food / Entertainments</th>

                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Emeka</td>
                  <td>€10 </td>
                  <td>€10 </td>

                </tr>
                <tr>
                  <td>Ugo</td>
                  <td>€10 </td>
                  <td>€10 </td>

                </tr>
                <tr>
                  <td>Chuks</td>
                  <td>€10 </td>
                  <td>€10 </td>

                </tr>
                <tr>
                  <td>Kingsley</td>
                  <td>€10 </td>
                  <td>£5</td>
                </tr>


                <tr>
                  <td>Francis</td>
                  <td>€10 </td>
                  <td>£5</td>

                </tr>
                <tr>
                  <td>Charley</td>
                  <td>€10 </td>
                  <td>€10 </td>

                </tr>
              </tbody>
            </Table>
          </div>
        </Container>

      </main>
    </div>
  );
}

export default Feb2022Meeting;







