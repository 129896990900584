
import { useState } from 'react';
import { Navbar, NavLink, Container, Nav, Offcanvas,CloseButton,Button } from 'react-bootstrap';
import {BsChevronCompactDown} from 'react-icons/bs';
import {BsJustify} from 'react-icons/bs'
import '../../../CSS/MenuBar.scss'
import logos from '../../image/NewImagelOGO.png' 
import styled from 'styled-components';
import Register from './Register';
import { useNavigate, useLocation, Link } from 'react-router-dom';



function Menu() {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [clicks, setClick] = useState(false);

  let navigate = useNavigate()

  const signTo = async () => {
    try {
      navigate("/SignIn"); // Omit optional second argument
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit Login" } }); 
    }
  };
  const navigateToGallery = async () => {
    try {
      navigate("/Gallary"); // Omit optional second argument
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit Login" } }); 
    }
  };
  const navigateToVideos = async () => {
    try {
      navigate("/Videos"); // Omit optional second argument
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit Login" } }); 
    }
  };
  const navigateToBlogs = async () => {
    try {
      navigate("/BlogPages"); // Omit optional second argument
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit Login" } }); 
    }
  };
  const navigateToRotaTable = async () => {
    try {
      navigate("/RotaTable"); // Omit optional second argument
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit Login" } }); 
    }
  };
  const navigateToHome = async () => {
    try {
      navigate("/"); // Omit optional second argument
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit Login" } }); 
    }
  };
  const VideoGallery = async () => {
    try {
      navigate("VideoGallery"); // Omit optional second argument
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit Login" } }); 
    }
  };


const location = useLocation();
console.log(location.state) 
  const handleOffcanvasClose = () => setShowOffcanvas(false);
  const handleOffcanvasToggle = () => setShowOffcanvas((prevState) => !prevState);

  return (
    <Navbar expand="lg"  variant="light" 
     className='px-5 py-2 nav-cover relative '>
      <Container fluid>

        <div href="#" className='brand-logo relative'>
        <NavLink href='/' className="logo  relative cursor-pointer">
          ICANWI
        </NavLink>
      
          <span className="logo-text px-3 ">
          <span>organisation with clear purpose | </span> 
          </span>
          <NavLink className="update   relative pl-4 cursor-pointer text-decoration-none text-light" href='UpdatePage'>
          Updates
        </NavLink>
        </div>

        <div className='navbar-toggle relative Nav-btn' 
         aria-controls="offcanvasExample" 
          onClick={handleOffcanvasToggle}>
          <BsJustify className="
           Bjustify hover-cursor"/>
    
        <Button 
         className = 'btn-menu border-none shadow-none focus:outline-none'>
         <span className='btn-menu-text'>Menu</span>  
              <span className='btn-icon pionter-cursor'>
                <BsChevronCompactDown/> 
              </span>
        </Button>
        </div>
        
        <Canva className='off-canva' show={showOffcanvas} 
         onHide={handleOffcanvasClose} placement="start">
          <Offcanvas.Header>
           <Offcanvas.Title>
           <NavLink  class="navbar-brand text-decoration-none relative" to ="/">
            <img src={logos} alt={'newLogo1'} 
             style={{position:'absolute',
              top:'-10px', left:'-12px',
              maxWidth:'200px', 
              maxHeight:'200px',
              minWidth:'100px'
              }}/>
             </NavLink>

            </Offcanvas.Title>
            <CloseButton variant = 'white' className='' onClick={()=>{
              setShowOffcanvas(handleOffcanvasClose)
            }}/>
          </Offcanvas.Header>
          <Offcanvas.Body className="offcanvas-body relative top-8 links-body">
    
        <Nav className="me-auto d-flex flex-col justify-center Nav">
        <LINKERS as={Link} to='/' target = {'_parent'}className='navs bg-transparent focus:outline-none shadow-none'>
          <span className='span'>Home</span>
        </LINKERS>

        <LINKERS className='navs bg-transparent focus:outline-none shadow-none' 
        as={Link} to='/UpdatePage'>
          <span className='span'>UpdatePage</span>
        </LINKERS>

        <LINKERS className='navs bg-transparent focus:outline-none shadow-none' 
        as={Link} to='/Gallary'>
          <span className='span'>Gallery</span>
        </LINKERS>

        <LINKERS  className='navsb bg-transparent focus:outline-none shadow-none'
         as={Link} to='/Videos' >
          <span className='span'>Videos</span>
        </LINKERS>

        <LINKERS  className='navs bg-transparent focus:outline-none shadow-none' 
        as={Link} to='/PrivatePage'>
          <span className='span'>Minutes</span>
        </LINKERS>

        <LINKERS  className='navs bg-transparent focus:outline-none shadow-none'
        as={Link} to='/PrivatePage' >
          <span className='span'>Finance</span>
        </LINKERS>

        <LINKERS  className='navs bg-transparent focus:outline-none shadow-none'
         as={Link} to='/PrivatePage'>
          <span className='span'>Constitution</span>
        </LINKERS>

        <LINKERS  className='navs bg-transparent focus:outline-none shadow-none'
        as={Link} to='/Acheivement'>
          <span className='span'>Acheivements</span>
        </LINKERS>
      

        <LINKERS  className='navs bg-transparent focus:outline-none shadow-none' 
         as={Link} to='/BlogPages'>
          <span className='span'>Historical Origins</span>
        </LINKERS>

        </Nav>

{/*        OFFCANVAS COMPONENT STARTS HERE
 */}




        </Offcanvas.Body>
        </Canva>
        <Nav className="buttons-right px-5">
          <Button variant="outline-primary btn1 outline-none shadow-none " 
          className="me-3"
          onClick={signTo} 
          >
          <span>Sign-in</span>
          </Button>
        
            <span><Register/></span>  
          </Nav>
      </Container>
    </Navbar>
  );
}
export default Menu;



const Canva = styled(Offcanvas)`
     overflow-y: scroll;
     &::-webkit-scrollbar {
      width: 10px; 
    }
    
    &::-webkit-scrollbar-track {
    background-color: #C3B1E1;
    }
    
    &::-webkit-scrollbar-thumb {
    background:#51414F;
    border-radius: 25px;
    }
    
    .btn-close{
    color:white !important;
    margin-top:18px !important;
    margin-right:8px !important;

    
    }
    .span{
    text-align:center !important;
    } 

`

const  LINKERS = styled(Button)`
 color: #fff3b0 !important;
          border-bottom: 1px solid #fff3b0 ;
    font-size:1.2rem !important;
    font-family:'Arial' sans-serif;
    font-weight:700;
    border-top:none !important;
    border-right:none !important;
    border-left:none !important;
    max-height:60px !important;
    min-height:60px !important;
    display:grid;
    grid-template-columns:repeat(1, auto);
    justify-content:center;
    align-items:center;
    position: relative;
    min-height:40px;
    background:transparent !important;
    transition: all 0.5s ease-in-out;
    &:hover{
      background:#eb5151 !important;
      color:black !important;
      
    }
    span{
    position:absolute;
    width:100%;
    left:50%;
    transform:translateX(-50%);
    top:50%;
   
    
   
  
    @media(max-width:996px){
      top:1.3rem !important;
      
    }
    @media(max-width:786px){
      font-size:1.5rem !important;
      top:1rem !important;
    
      }

    }
`




      

    
 

