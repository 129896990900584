import React from 'react';
import '../../CSS/Rota.scss';
import { MDBCheckbox } from 'mdb-react-ui-kit';
import TopMenu from '../Header/DropdownContainer/TopMenu';





const RotaTable = () => {
  const columnNames = ['Name', 'Month', 'Date', 'Next Host', 'Hosted'];
  
  return (
    <>
    {<TopMenu/>}
       <div className="rota-container relative top-24">
      <h1 className="title">Meeting Hosting Rota</h1>
      <p className="description">
        This is the Hosting schedule for all the constitutional Members. It should be noted that the Rota follows a sequence based on surnames. 
        In order to ensure uninterrupted hosting, members can exchange hosting duties.
      </p>
      <table className="rota-table">
        <thead>
          <tr>
            {columnNames.map((columnName) => (
              <th key={columnName}>{columnName}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Francis Udoku</td>
            <td>April</td>
            <td>09-04-2023</td>
            <td></td>
            <td><MDBCheckbox name='flexCheck' className='relative left-4' id='flexCheckChecked' defaultChecked/>
            </td>
          </tr>
          <tr>
            <td>Chijioke Ogujuwa  </td>
            <td>May</td>
            <td>14-05-2023</td>
            <td>
         
            </td>
            <td>
            <MDBCheckbox name='flexCheck' className='relative left-4' id='flexCheckChecked' defaultChecked/>
            </td>
          </tr>
          <tr>
            <td> Charles Obodoechina</td>
            <td>June</td>
            <td>11-06-2023</td>
            <td>
           
            </td>
            <td>
            <MDBCheckbox name='flexCheck' className='relative left-4' id='flexCheckChecked' defaultChecked/>
            </td>
          </tr>
          <tr>
            <td>Emeka Onwuzo</td>
            <td>March</td>
            <td>12-03-2023</td>
             <td></td>
            <td><MDBCheckbox name='flexCheck' className='relative left-4' id='flexCheckChecked' defaultChecked/>
            </td>
          </tr>
          <tr>
            <td>Summer Party</td>
            <td>July</td>
            <td>16-07-2023</td>
             <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Innocent Ike</td>
            <td>August</td>
            <td>13-8-2023</td>
            <td>
            <MDBCheckbox name='flexCheck' className='relative left-4' id='flexCheckChecked' defaultChecked/>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>Kingsley Agoha</td>
            <td>September</td>
            <td>10-09-2023</td>
            <td>
         
            </td>
            <td></td>
          </tr>
          <tr>
            <td>Austin O Okeagu</td>
            <td>October</td>
            <td>08-10-2023</td>
            <td>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>Ike Nwadike</td>
            <td>November</td>
            <td>12-11-2023</td>
            <td>
           
            </td>
            <td></td>
          </tr>
          <tr>
            <td>Okezie Emmanuel</td>
            <td>February</td>
            <td>11-02-2024</td>
            <td>
           
            </td>
            <td></td>
          </tr>
          
           <tr>
            <td>Ugo Nwankwo</td>
            <td>March</td>
            <td>10-03-2024</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    </>
  );
};

export default RotaTable;
