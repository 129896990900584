import React from 'react';
import styled from 'styled-components';
import '../../CSS/UpdatePage.scss'
import TopMenu from '../Header/DropdownContainer/TopMenu';
import img from '../image/newkids.jpeg'

function UpdatePage() {
  return (
    <>
    {<TopMenu/>}
    <UPDATE className="update-page relative top-16">
      <div className="update-header">
        <img className='img-fluid 
        ' src={img} alt = 'kids image'/>
        <div className="overlay"></div>
      </div>
    
      <div className="body bg-slate-500">
      <h1 className='text-dark page-description'>Important Updates</h1>
        <div className="section s-1">
          <h3>Next Meeting | Sept 10, 2023</h3>
          <p>
          The upcoming meeting has been arranged for September 10, 2023, at the residence of our chairman in London Derry. <strong>Agenda</strong> IUD New yam Festival Attendance Review /  Lesson Learned<br/>
          Family Christmas Party<br/> Igbo Union Potential Affiliatnion<br/> Any Other Business
          Address: 120 Sevenoaks Crescent Bt47 6Bd Derry. The meeting is set to commence at 4:00 PM sharp. If you have any inquiries regarding directions to the venue, kindly reach out to Mr. Agoha at +44 7596 573084. We kindly request all attendees to make every effort to arrive punctually.         
          </p>
        </div>
        <div className="section s-2">
          <h3>April 8, 2023</h3>
          <p>Sed eget risus eu odio pulvinar ultricies vel ac eros. Fusce varius dui eu urna dictum placerat.</p>
        </div>
      
      </div>
    </UPDATE>
    </>
  );
}

export default UpdatePage;

const UPDATE = styled.div`


`