
import React, {useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Modal, Button, NavLink} from 'react-bootstrap';
import { Link } from "react-router-dom";
import styled from "styled-components";

const Modals = () =>{
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
      <REG className="mode">
        <p className= "btn btn-customised nextButton relative bg-transparent outline-none shadow-none text-light"
         onClick={handleShow} >
         
        <span className='sign-ups '>Sign up</span>
        </p>
  
        <Modal show={show} onHide={handleClose} centered  className="card-body p-4 modes p-sm-5 P-5 card-registration">
          <Modal.Header className="modehead" closeButton>
            <ModalTitle className='modet'>
            
            <h5 className="card-title text-light text-center mb-5 fw-light fs-5">Register</h5>
                </ModalTitle>
          </Modal.Header>


<Modal.Body className="bg-dark">      
     <form className="mt-n2">
        <div className="form-floating mb-3">
            <input type="text" className="form-control" id="floatingInputUsername"
                placeholder="myusername" required autofocus />
            <label for="floatingInputUsername">Username</label>
        </div>
        <div className="form-floating mb-3">
            <input type="email" className="form-control" id="floatingInputEmail"
                placeholder="name@example.com" />
            <label for="floatingInputEmail">Email address</label>
        </div>
        <hr />
        <div className="form-floating mb-3">
            <input type="password" className="form-control" id="floatingPassword"
                placeholder="Password" />
            <label for="floatingPassword">Password</label>
        </div>
        <div className="form-floating mb-3">
            <input type="password" className="form-control" id="floatingPasswordConfirm"
                placeholder="Confirm Password" />
            <label for="floatingPasswordConfirm">Confirm Password</label>
        </div>
        <div className="d-grid mb-2">
            <button className="btn btn-lg btn-secondary btn-login fw-bold text-uppercase"
                type="submit">Register</button>
        </div>
        <a href ="/SignIn" className="d-block text-center mt-2 small">
            Have an account? Sign In
        </a>
        <hr className="my-4"/>
        <div className="d-grid mb-2">
            <Button style={{
             color: 'white !important',
             backgroundColor: '#ea4335',
             border:'none !important', maxWidth:'100px !important'
            }} 
            
            className="btn btn-lg btn-google btn-login fw-bold text-uppercase" type="submit">
            <i class="bi bi-google me-2">
                </i>Sign up with Google
            </Button>
        </div>
        <div className="d-grid">
            <Button  style={{  
            color: 'white !important',
            backgroundColor: '#3b5998',
             }} 
            
            className="btn btn-lg    btn-facebook btn-login fw-bold text-uppercase"
                type="submit">
                 <i class="bi bi-facebook me-2">
                    </i>Sign up with Facebook
            </Button>
        </div>
    </form>  


    </Modal.Body>


          <Modal.Footer>
          <div className="modal-footer">
          <Button variant="danger btn-lg" onClick={handleClose}>
              Close
            </Button>
           </div>
          </Modal.Footer>
        </Modal>
      </REG>
    );
  }
  
export default Modals
const REG = styled.div`
 .nextButton{
    transform: translateY(10px) !important;
    border: 1px solid white !important; 
   padding:0.5rem 2rem !important;
    color: white !important;
    z-index:1000;
    transition: all 0.3s ease;
    -webkit-box-shadow: #FFF 0 -1px 4px, rgb(255, 0, 0) 0 -2px 10px, #ff8000 0 -10px 20px, red 0 -18px 40px, 5px 5px 15px 5px rgba(0,0,0,0); 
    box-shadow: #FFF 0 -1px 4px, rgb(255, 0, 0) 0 -2px 10px;
   @media(max-width:992px){
    padding:0.8rem 3rem !important;
    .sign-ups{
        font-size: 1.2rem !important;
        font-family: Arial, sans-serif; 
   
    }
   }
    &:focus{
        outline:none !important;
        border:none !important;
        box-shadow:none !important;
    }
 
 }
 `
 const ModalTitle = styled(Modal.Title)`
 
 `