



import React from 'react';
import Navbars from '../../MinutesPage/Navbars'
import {Container, Navbar, Col, Row } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';


function Match2022Meeting() {
  return (
    <div className="Article-cover">
    {<Navbars/>}

   {/*    page body starts here */}

   <main className='main'>
     
   <h2> Minutes for March</h2>
   <p style={{fontSize:'1.1rem'}}>
   <b>Date:</b> 13/03/2022
   <br/><br/>
   <strong>Members Present:</strong> <span className='m-present'>.
   Kingsley, Obodoechina, Francis, Emeka, Ugo, Chuks and obinna
   </span>
   <br/><br/>
   <strong>Members Absent:</strong> <span className='m-abscent'>
   CJ, Billy and Eloka
   </span>
   </p>
   <h5>Proceedings</h5>
   <p>
   The Meeting was called to order at 5: PM. Open prayer was lead by Chairman. Minutes of the last meeting were read by francis and approved as corrected by Chuks and Kinsley. The members extended a warm welcome to visitors Chidi Usanga and Onyedikachi and expressed gratitude for their attendance. The VC encouraged them to participate in committee programs either individually or as a group.
  </p>

  <h5>Item discussed:</h5>
   <p>
   <span className='text-danger'>Charitable Status Application</span> | <span className='text-primary'>Meeting Hosting</span>
  </p>
  <p> During the discussion on obtaining charitable status, Francis reported that the application process had already begun, and its progress will be reviewed during upcoming meetings. In regards to meeting hosting, there was an extensive discussion on how and where meetings should be held. Francis expressed his opinion that the financial burden of hosting meetings should be eased by having the association provide food and drinks, while the host is only responsible for the venue. He emphasized that this would not only encourage members to host meetings but also make them more likely to accept hosting plans. However, Kingsley, Obinna, and Chuks suggested that the host should be responsible for hosting and only provide what they can afford. The latter suggestion was approved by a majority vote</p>

   <h5> Any Other business</h5>
   <p>
   Ugo invited the association to join him in his campaign against hard borders on immigrants. However, members had a difficult time determining whether to accept or decline the invitation because they were unclear about the conditions and modalities of the protest. In the end, it was decided that the reasons for the protest must be clarified before any decisions can be made
   </p>
  
   <h5>Finance</h5>
   <p>
   The sum of €120 was collected, €115 balance from Party contribution was also collected, €60 was given to the host, and €175 was given to the treasurer.
              The motion for adjournment was moved by francis, seconded by Billy at 7:30 pm.  
              The next meeting is scheduled for Mar 8th, 2020. 
              The closing prayer was said by Vc
</p>



</main>
</div>
  )
}
export default Match2022Meeting



          


         


            
           
         