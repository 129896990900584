

import React from 'react';
import Navbars from '../../MinutesPage/Navbars'
import {Container, Navbar, Col, Row } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';


function March2023Meeting() {
  return (
    <div className="Article-cover">
    {<Navbars/>}

   {/*    page body starts here */}

   <main className='main'>
     
   <h2> Minutes for March</h2>
   <p style={{fontSize:'1.1rem'}}>
   <b>Date:</b> 12/03/2023
   <br/>
   <strong>Members Present:</strong> <span className='m-present'>.
   Kingsley Agoha, Charles Obodoechina, Emeka Onwuzo, Ugo Nwankwo, Chuks,Innocent, Austin, Emanuel Okozie and Soki
   </span>
   <br/><br/>
   <strong>Members Absent:</strong> <span className='m-abscent'>
   Billy, Chidi Usanga, Nwadike, Chidebere and Edozie.
   </span>
   </p>

<h5>Topics Discussed</h5>


<p className='text-dark'>
•	Financial Record Update<br/>
•	Madam Uche Okoh's Visitation<br/>
•	Matters Arising<br/>
•	Election of New Officers<br/>
•	Finance<br/>

</p>



   <h5>Proceedings</h5>
   <p>
   The meeting was convened at 5:30 PM, with Kingsley leading an opening prayer. This was followed by a report on absenteeism and apologies. The minutes from the previous meeting was read by Charles, which were subsequently approved by Kingsley and seconded by Chuks.
An agenda suggested by Innocent during the previous meeting, which had unintentionally not been captured, was duly corrected and incorporated. Innocent took the minutes for the current meeting. The chairman initiated the proceedings with a cordial welcome to all attendees and inquired about the members who were not present
  </p>


   <h5>	Financial Account Update</h5>
   <p>
   Upon reviewing the financial report presented by the treasurer, the chairman verified the accuracy of the report. Kingsley likewise endorsed the report and took the opportunity to extend an apology to the association on behalf of the financial secretary for the inability to provide our financial information in real time. He reassured the assembly that such lapses in furnishing critical information of this kind would be prevented in the future. Emeka and Chuks were appointed to investigate the association's accounts and promptly agreed to convene at the bank to rectify the account.

  </p>


  <h5>Madam Uche Okoh's Visitation:</h5>
  <p>
  
  In light of Emmanuel's proposal to visit Ifeoma Okoh, who recently lost her husband, Kingsley emphasized that our constitution does not mandate condolence visits to the spouses of deceased members. He clarified that our bereavement protocol extends solely to members, their parents, or children. Kingsley proposed that, as an alternative, members could offer moral support by escorting our female members to the widow's visitation. Following extensive deliberation, a consensus was reached that members could voluntarily support the women in paying condolence visits to the widow.
  </p>

  <h5>Matters Arising:</h5>
  <p>
  Emmanuel introduced a contributory plan, suggesting that members contribute on a monthly basis and take turns accessing the accumulated contributions. The idea aimed to collectively bolster members' financial well-being. The response highlighted that a similar proposal had been raised in the past. However, it faced challenges in materializing primarily due to concerns regarding potential payment defaults. Nevertheless, while the concept was appreciated, it was collectively agreed that the plan would be better organized outside the group's official program. Members who shared a similar mindset could choose to form a smaller groups and collaboratively implement the idea within those settings
  </p>

  <h5>Any Other Business:</h5>
   <p>
   A visitor, identifying himself as Joseph from Mbaise in Imo State, Nigeria, expressed his desire to become a member of the association. He received a warm welcome from all attendees. Additional details regarding the registration process and the association's code of conduct will be shared with him at an appropriate time.
   </p>

  <h5>Election of New Officers</h5>
   <p>
   <p><strong><u style={{color:'brown'}}>Election Proceedings:</u></strong></p>
   <p><strong className='text-primary'>Return Officer: </strong>Ugo Nwankwo</p>
   <p><strong className='text-primary'>Elected Chairman: </strong>Kingsley Agoha with 6 votes</p>
   <p><strong className='text-primary'>Elected VC. Chairman: </strong>Ugo Nwankwo with 5 votes</p>
   <p><strong className='text-primary'>Elected Secretary: </strong>Francis Udoku with 9 votes</p>
   <p><strong className='text-primary'>Elected Treasurer: </strong>Austin with 4 votes</p>
   <p><strong className='text-primary'>Elected Finacial Secretary: </strong>Innocent Ike with 3 votes</p>
   <p><strong className='text-primary'>Elected Provost: </strong>Emmanuel Okezie with 4 votes</p>

   </p>


   <h5>Finance</h5>
   <p>
The sum of €110 and £30 were collected for dues and handed over to the treasurer. The motion for adjournment was moved by Chuks, seconded by Soki at 7:05pm.  
The next meeting is scheduled for April 16th, 2023. 
The closing prayer was said by Charle. 
</p>

   <Container>
         <Row className='d-flex justify-content-center align-items-center'>
           <strong><h4 className='text-danger p-2 px-0 sumary'>
             Summary of the Dues</h4>
           </strong>
           </Row>

           <div className='tab' style={{minWidth:'400px',maxWidth:'400px'}}>
   
         <Table striped bordered hover variant="dark">
         <thead>
           <tr>
           <th>NAMES</th>
           <th>DEUS</th>
           
      
           </tr>
         </thead>

         <tbody>
         <tr>
           <td>Emeka</td>
           <td>€10 </td>
        </tr>
         <tr>
           <td>Soki</td>
           <td>€50 : April - June </td>
        </tr>
         <tr>
           <td>Nwadike</td>
           <td>not-paid </td>
        </tr>
         <tr>
           <td>Ugo</td>
           <td>not paid </td>
        </tr>
         <tr>
           <td>Austin</td>
           <td>€10 </td>
        </tr>
         <tr>
           <td>Chuks</td>
           <td>€10 </td>
          
     
        </tr>
         <tr>
           <td>Kingsley</td>
           <td>€10 </td>
         
        </tr>
      
        
         <tr>
           <td>Francis</td>
           <td>€10 </td>
        </tr>

         <tr>
           <td>Charley</td>
           <td>€10 </td>
        </tr>
         <tr>
           <td>Emmanuel</td>
           <td>£10 </td>
        </tr>
         <tr>
           <td>Jerry Junior</td>
           <td>£10 </td>
        </tr>
         <tr>
           <td>Innocent Ike</td>
           <td>£10 </td>
        </tr>
        
        </tbody>
         </Table> 
         </div>
       </Container>

 </main>
</div>
);
}

export default March2023Meeting;


         


           
              
      