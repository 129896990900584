 import React from 'react'
import { Link } from "react-router-dom";
import '../../CSS/Navbars.scss'


export const Navbars = () => {
  return (
    <div>
       <header>
        <nav className="minutes-page-navbars relative">
          <div className="left-sections">
            <span  className='me-2 reading'>Still Reading...</span>
            <Link to = '/PrivatePage' className='mx-3 button-left text-decoration-none text-light buttons border-none outline-none shadoww-none'>Read More</Link>
          </div>
          <div className="right-sections">
            <span className='me-2 done '>Done ?</span>
            <Link to='/' target={'_parent'} className='mx-3 button-right text-decoration-none text-light buttons border-none outline-none shadoww-none' >Logout</Link>
          </div>
        </nav>
      </header>
    </div>
  )
}
export default Navbars 