



import React from 'react';
import Navbars from '../../MinutesPage/Navbars'
import {Container, Navbar, Col, Row } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';


function May2023Meeting() {
    const myList = ['Permanent Meeting Venue', 'Family Summer fun day', 'Our Website', 'Any other Business ', 'Finance '];   
    const listItems = myList.map((myList)=>{   
    return <li>{myList}</li>;   
}); 
  return (
    <div className="Article-cover">
    {<Navbars/>}

   {/*    page body starts here */}

   <main className='main'>
     
   <h2> Minutes for May</h2>
   <p style={{fontSize:'1.1rem'}}>
   <b>Date:</b> 21/05/2023
   <br/><br/>
   <strong>Members Present:</strong> <span className='m-present'>.
   : Kingsley, Obodoechina, Emeka Onwuzo, Emmanuel Okezie, Chijioke Ogujawaa, Joseph Opara, Francis, Uche Alum and Obinna
   </span>
   <br/><br/>
   <strong>Members Absent:</strong> <span className='m-abscent'>
   Ugo, Chidi Usanga, Chidiebere, Sokey, Innocent and Nwadike
   </span>
   </p>
   <h5>Agenda</h5>
   <div className='text-dark my-2'>
    {myList.map(list=>
        <li>{list}</li>
        )}
   </div>

   <h5>Proceedings</h5>
   <p>
   The meeting commenced promptly at 5 PM. Brother Emmanuel Okezie led an open prayer, after which Francis offered a libation to the God of Umu Igbo, beseeching for liberation, favor, and abundant blessings for all attendees and the host. Francis also read the minutes of the previous meeting, which were subsequently approved by the Chairman.
  </p>

  <h5>Permanent Meeting Venue:</h5>
  <p>
  The chairman requested a status update on the progress of securing a permanent meeting venue, specifically in regard to the progress made in the process of securing the Ballybofey church hall. In response, Francis explained that he had reached an agreement with the property management, and that the rental fee would be set at €40 per month. However, he noted that the venue may not be available if there is a funeral or church program on the date of the meeting - because such events take precedence over other activities at the venue. The agreement was well received by all parties. However the chairman also urged members from Derry to keep searching for a venue in that area, stating that any affordable venue similar to the Ballybofey church hall in Derry would be considered.

  </p>

   <h5> Family Summer fun day</h5>
   <p><strong><u className='text-danger'>Date:</u></strong>  A decision was made to have the summer outing on July 16th in order to accommodate the African Day celebration on July 1st.<br/> <strong><u className='text-danger'>Venue:</u></strong>
   Chuks proposed finding a free location. Building on this suggestion, Francis recommended Emeka's place. The chairman then instructed Emeka to confirm with his girlfriend if their house would be available as suggested. Additionally, Francis proposed that Lizzy be given a title to show appreciation for her support to the group over the years and to further engage her in group activities, whilst strengthening our integration status.<br/><strong><u className='text-danger'>Budget:</u></strong> Chairman suggested a budget of €500 for the summer event but expressed concern regarding the financial status of the members. To alleviate this, a contribution fee of €25 to €30 per member was suggested, with the rest covered by group funds. Chuks also supported the idea that any voluntary donations should be supplemented by the association's funds.
   </p>
   <h5>Website</h5>
   <p>
   The Chairman made an official announcement regarding the completion and launch of the group's website. He expressed great satisfaction with this significant milestone in the group's history, emphasizing that it allows the world to witness and experience our organization's endeavors. He encouraged all members to visit the website and actively share it with others.
   </p>
  <h5> Any Other Business</h5>
    <p>
    The chairman expressed gratitude to the members for attending the official opening of his family business, Sandy's African Food Hub, in Derry. Additionally, Ogidi presented a bottle of hot drink as a token of appreciation to the members, showing his love and gratitude. In addition, Uche Alum brought a bottle of wine as an apology for his inactiveness since arriving in Ireland in December of last year. The chairman thanked Uche not only for acknowledging his mistakes but also for taking steps to rectify them. He encouraged Uche to make himself available whenever possible.
    Regarding Billy, the chairman took the opportunity to address Billy's situation, reminding everyone that a warning letter had been previously sent to him regarding his own inactivity, but no response was received. As a result, it was collectively agreed upon to send a follow-up letter informing Billy that, based on Article 14, Section II -h of our constitution, he is no longer considered a member of the group. However, the secretary confirmed that the agreed-upon letter had not been sent, although he had spoken to Billy about the matter. Taking action, the chairman instructed the secretary to promptly send the letter before the next meeting

    </p>
 
   <h5>Finance</h5>
   <p>
   A total of 70 euros in dues were collected and handed over to the treasurer. Ogidi moved a motion for adjournment, which was seconded by francis. The next meeting was scheduled for 11th of June 2023 at St Mary’s Parish Center Stranorlar. The closing prayer was led by Ogidi
</p>



</main>
</div>
  )
}
export default May2023Meeting



          


         


            
           
         