
import React from 'react';
import Navbars from '../../MinutesPage/Navbars'
import { Container, Navbar, Col, Row } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';


function June2022Meeting() {
  return (
    <div className="Article-cover">
      {<Navbars />}

      {/*    page body starts here */}

      <main className='main'>

        <h2> Minutes for June</h2>
        <p style={{ fontSize: '1.1rem' }}>

          <b>Date:</b> 12/06/2022
          <br /><br />
          <strong>Members Present:</strong> <span className='m-present'>
            Kingsley Agoha, Austine Okeagu, Chuks Ogujawa, Charles Obodoechina, Francis Udoku, Emeka Onwunzo, Billy             Banda, Ugo Nwankwo
          </span>
          <br /><br />
          <strong>Members Absent:</strong> <span className='m-abscent'>
            Chijioke and Eloka
          </span>
        </p>

        <h5>Proceedings</h5>
        <p>
        Francis read the minutes from the previous meeting, while Charles Obodoechina took notes for the current meeting. During an open address, the chairman conveyed appreciation to everyone who took part in the Relay for Life occasion and inquired if there was any feedback from the event's organizers.
        </p>

        <p>
        Francis reported that the organizers expressed their great admiration and sent a message of gratitude to ICANWI for participating, especially for highlighting our cultural values, which added to the event's vibrancy. The VC also thanked all members for their participation, specifically acknowledging Mr. Francis for chairing and leading the event for the association. The chairman then inquired about feedback from Eloka, and Ugo responded that he had spoken to Eloka but it appears that he is no longer interested in the association
      </p>

      <p>
      Chuks inquired whether the constitution had been reviewed to determine the appropriate course of action. Francis responded affirmatively, stating that the disciplinary procedures outlined in the constitution for dealing with member misconduct had been reviewed. However, Francis also noted that issuing a written warning was not a part of the disciplinary procedure, and emphasized that such actions should cease since it is not in line with the constitution. Chuks concurred with Francis, emphasizing the importance of adhering to the constitution to avoid any potential biases. The chairman officially closed Eloka's case, ruling that if he desires to rejoin the group, he must follow the disciplinary procedure outlined in the constitution.
      </p> 
   
      <h5>Any Other Business</h5>
      <p>
      Francis suggested upgrading our masquerade to attract more events to the association. It was agreed to be discussed at the next meeting.
      </p>
      <h5>Finance</h5>
      <p>
        80 EUROS collected from 8 members present.
      </p>
    </main>
        </div >

  )
}

export default June2022Meeting













