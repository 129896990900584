
import React from 'react';
import Navbars from '../../MinutesPage/Navbars'
import {Container, Navbar, Col, Row } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';
import styled from 'styled-components'


function Oct2022Meeting() {
  return (
    <div className="Article-cover">
 {<Navbars/>}
 
   {/*    page body starts here */}

   <main className='main'>
     
   <h2> Minutes for October</h2>
   <p style={{fontSize:'1.1rem'}}>
   <b>Date:</b> 9/10/2022
   <br/><br/>
   <strong>Members Present:</strong> <span className='m-present'>.
   Kingsley Agoha, Austine Okeagu, Chuks Ogujawa, Charles Obodoechina, Francis Udoku, Emeka Onwunzo, Emmanuel 
   </span>
   <br/><br/>
   <strong>Members Absent:</strong> <span className='m-abscent'>
   Chijioke, Ugo, Chidi, Edozie, Chidiebere and Billy
   </span>
   </p>
   <h5>Proceedings</h5>
   <p>
   Meeting was called to order at 5:20pm. The open prayer was said Emeka Onwunzo. The minutes of the meeting were taken by the chairman and previous minutes were also read him. Motion to approve the minutes was made by francis, seconded by Kingsley. 
  </p>

  <h5>Agenda</h5>
   <p>
   <UL className=''>
              <li>1. Charity status update</li>
              <li>2. Financial and bank signatories</li>
              <li>3.   End of year/Welcome Party: </li>
    </UL>
  </p>

   <h5> Charity status update</h5>
   <p>
   Francis updated the members following a discussion at Mr Gabriel's that the constitution had been circulated as agreed and members had acknowledged receipt. They also have promised to provide feedback shortly. Although no feedback has yet been recevied but he believes that they are working on it as they do communicate regularly. Francis apologises for not being able to work on the application process as he has been busy developing our website.  
   </p>
  
   <h5> Financial and bank signatories</h5>
   <p>
   Chairman asked Chuck and Francis if Ugo had communicated with them regarding the bank and financial signatories as discussed in the last meeting. Chuck replied no. It was decided that Francis and Emeka would accompany Chuck to the bank to resolve the signatory's issue.

   </p>
   <h5>  End of year/Welcome Party:</h5>
   <p>
   The chairman asked for an update regarding the end of year party, as well as our ideas for the welcome party for Father Ohara. Emeka believes that the welcome party is a worthwhile idea because the Rev father did a good job. However, he was concerned about funding and the venue, unsure of how they could be handled. In view of funding, Chucks asked if there would be a mandatory contribution in addition to our normal contribution or would our usual approach apply? Francis noted that we might get some support from people when the event approaches. 
      <p><br/>
        Chuk advised that we should work with what we have, and external support would be a plus.
      Francis pointed out that choosing a venue around the Ballybofey{'/'}Stranorlar area would be strategic as it would encourage the friends and family of Father Ohara to attend the program. Emeka warned that we might not be allowed to bring our food or decorate the venue as we wish. Emmanuel strongly advised that the venue should be arranged first, and the theme welcome party should be replaced by an appreciation party. In the end, it was decided that we should maintain our usual fund-raising method and a contribution of 100 euros should be made by each member towards the event. Also, Francis and Emeka were directed to find a venue and report back to the group at the next meeting. 
      </p>
      In addition, <u> End of Year  {'/'} Appreciation Party</u> was chosen as the theme of the event instead of <u>End of Year {'/'} welcome Party</u> Furthermore, Emeka noted that we are unsure which member of the executive is the financial Secretary- emphasizing that the end of the year is fast approaching, and it's critical that we have an annual financial statement for informed decision-making. He complained that some people had not paid their dues and other contributions that needed to be recorded. Chairman acknowledged the point and expressed concerns about Ugo's refusal to fulfill his obligations - stressing that he was elected to serve in the position, and he is expected to execute his duties as assigned. However, he promised to speak to Ugo before the next meeting                
      </p>
   
  
   <h5>Finance</h5>
   <p>
   70 euros was collected and was used To offset the cost of hosting the meeting.
   </p>

   <Container>
              <Row className='d-flex justify-content-center align-items-center'>
                <strong><h4 className='text-danger p-2 px-0 sumary'>
                  Summary of the Dues and Payement</h4>
                </strong>
                </Row>

                <div className='tab' style={{minWidth:'400px',maxWidth:'400px'}}>
        
              <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                <th>NAMES</th>
                <th>DEUS</th>
           
                </tr>
              </thead>

              <tbody>
              <tr>
                <td>Emeka</td>
                <td>€10 </td>
                
             </tr>
            
              <tr>
                <td>Chuks</td>
                <td>€10 </td>
          
             </tr>
              <tr>
                <td>Kingsley</td>
                <td>€10 </td>
             </tr>
              <tr>
                <td>Obinna</td>
                <td>€10 </td>
             </tr>
              <tr>
                <td>Emmanuel</td>
                <td>€10 </td>
         
             </tr>
            
              <tr>
                <td>Francis</td>
                <td>€10 </td>
            
             </tr>
              <tr>
                <td>Charley</td>
                <td>€10 </td>
            
             </tr>
             </tbody>
              </Table> 
              </div>
            </Container>

</main>
</div>
  )
}
export default Oct2022Meeting

     const UL = styled.ul`
     li{
  margin-left:-2rem !important;
     }
     ` 

