

import React from 'react';
import Navbars from '../../MinutesPage/Navbars'
import {Container, Navbar, Col, Row } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';


function Feb2023Meeting() {
  return (
    <div className="Article-cover">
    {<Navbars/>}

   {/*    page body starts here */}

   <main className='main'>
     
   <h2> Minutes for February</h2>
   <p style={{fontSize:'1.1rem'}}>
   <b>Date:</b> 5/02/2023
   <br/>
   <strong>Members Present:</strong> <span className='m-present'>.
   Kingsley, Obodoechina, Francis, Emeka, Ugo, Chuks,Innocent, Austin and Soki
   </span>
   <br/><br/>
   <strong>Members Abcent:</strong> <span className='m-abscent'>
   Billy, Emmanuel Okozie, Chidi Usanga, Nwadike, Chidebere and Edozie.
   </span>
   </p>

<h5>Topics Discussed</h5>


<p className='text-dark'>
•	End of the year Party Update<br/>
•	Election of New Officers<br/>
•	Donation to New Nigeria Group<br/>
•	Re-instatement of Inactive Members<br/>
•	Matters Arising
</p>



   <h5>Proceedings</h5>
   <p>
   The Meeting was called to order at 5:15 PM. Open prayer was said by Francis. The minutes of the last meeting were read by Francis. It was approved by Chucks and seconded by Kingsley. Francis also took the minutes of the current meeting. As usual, the chairman welcomed all attendees and inquired about the absent members. 
  </p>


   <h5>End of the year Party Update</h5>
   <p>
   
   During the meeting, the chairman requested feedback regarding the End-of-year / Appreciation Party. Specifically, he asked whether everything went smoothly or if there were areas in need of improvement. Innocent observed that the preparations seemed disorganized and delayed, resulting in a poorly structured event. He recommended that future events be adequately prepared with well-organized activities carried out in a timely manner. Kingsley noted that unfavorable weather conditions and a shortage of volunteers had a negative impact on both the planning and execution of the event. Stressing that only a small number of members took on the responsibility of organizing the event. He encouraged all members to make themselves available to ensure that future events are successful and achieve their intended goals. Nevertheless, the event was a successful and memorable one he concluded.
  </p>


  <h5>Election of New Officers:</h5>
  <p>
  The election was not held because there was a low turnout of members at the meeting.
  However, it was rescheduled for the next meeting.
  
  </p>

  <h5>Stanley's Membership Review:</h5>
  <p>
  In accordance with our constitution, any member who leaves the group and subsequently wishes to re-join must submit a written request. As Stanley has not yet submitted his request, it was agreed  that his case should wait until he does so      
  </p>

  <h5>Charitable Status review:</h5>
   <p>
   An application for charitable status was discussed, as well as issues regarding governance and standard requirements. A decision was reached to move forward with the application, where progress would be discussed at each meeting.
   </p>

  <h5>St Patrick Event</h5>
   <p>
   St. Patrick's event was genuinely discussed. However, considering the resources involved in the preparation and the amount of time and money it will take, it was decided to not participate this year. We look forward to coming back next year with style.
   </p>
  
   <h5>Any Other Business</h5>
   <p>
   Chariman suggested a condolence visit to Uche Alomu on a free will donation basis. Ugo also suggested a courtesy visit to the Nigerian Ambassador in Dublin. The suggestions were supported by everyone, but no date has been set yet
   </p>

  
   <h5>Finance</h5>
   <p>
The sum of €60 was collected for dues. The €10 each was given to Chuks and Ugo as their part payment and  €40 was given to the treasurer. Also, $10 + €40 were collected for food and used to pay for it. The motion for adjournment was moved by Francis, seconded by Vc at 7:45pm.  
The next meeting is scheduled for Mar 13th, 2022. 
The closing prayer was said by Francis. 
</p>

   <Container>
         <Row className='d-flex justify-content-center align-items-center'>
           <strong><h4 className='text-danger p-2 px-0 sumary'>
             Summary of the Dues and Payement</h4>
           </strong>
           </Row>

           <div className='tab' style={{minWidth:'400px',maxWidth:'400px'}}>
   
         <Table striped bordered hover variant="dark">
         <thead>
           <tr>
           <th>NAMES</th>
           <th>DEUS</th>
           <th>Food / Entertainments</th>
      
           </tr>
         </thead>

         <tbody>
         <tr>
           <td>Emeka</td>
           <td>€10 </td>
           <td>€10 </td>
           
        </tr>
         <tr>
           <td>Ugo</td>
           <td>€10 </td>
           <td>€10 </td>
         
        </tr>
         <tr>
           <td>Chuks</td>
           <td>€10 </td>
           <td>€10 </td>
     
        </tr>
         <tr>
           <td>Kingsley</td>
           <td>€10 </td>
           <td>£5</td>
        </tr>
      
        
         <tr>
           <td>Francis</td>
           <td>€10 </td>
           <td>£5</td>
       
        </tr>
         <tr>
           <td>Charley</td>
           <td>€10 </td>
           <td>€10 </td>
       
        </tr>
        </tbody>
         </Table> 
         </div>
       </Container>

 </main>
</div>
);
}

export default Feb2023Meeting;


         


           
              
      