
import React from 'react';
import Navbars from '../../MinutesPage/Navbars'
import { Container, Navbar, Col, Row } from 'react-bootstrap'


function April2022Meeting() {
  return (
    <div className="Article-cover">
      {<Navbars />}

      {/*    page body starts here */}

      <main className='main'>

        <h2> Minutes for April</h2>
        <p style={{ fontSize: '1.1rem' }}>

          <b>Date:</b> 10/04/2022

          <br /><br />
          <strong>Members Present:</strong> <span className='m-present'>Kingsley, Obodoechina, Francis, Emeka, Ugo.</span>
          <br /><br />
          <strong>Members Absent:</strong> <span className='m-abscent'>CJ, Billy, Chuks, Obinna and Eloka</span>
        </p>

        <h5>Proceedings</h5>

        <p>
          The Meeting was called to order at 5:45PM. Open prayer was said by Francis. The minutes of the last meeting were read by francis and motion to approve it was made by Kinsley, seconded by chairman. The Items discussed were not limited to <strong><i className='text-danger' >charitable status application </i> and <i className='text-danger'>Relay 4 life event Invitation.</i></strong>. The minutes were taken by Francis as well
          .</p>

        <p>
          Regarding charitable status, the chairman assured the association that the application process is in progress, and updates would be provided at subsequent meetings until the application is approved. Vc, in his remarks, encouraged the association to be persistent and courageous in working towards the achievement of charitable status.
        </p>

        <p>
          Regarding the Relay 4 life event Invitation: Ugo said that the event is a strategic one and since it is a community engagement initiative, taking part is a proactive step to increase our presence in the community. He encouraged the association to give it a thought. VC agreed with Ugo, emphasizing the importance of participation in light of our core values. In the end, a consensus was reached regarding participation
        </p>

        <p>
          The participation led to further discussions about what type of event would be most effective in raising funds. Chairman suggested on site Masquerade dancing activity, VC suggested children dancing, and proposed the idea of raising money prior to the event. Ugo suggested asking the event organizers first. The next meeting will review both the potential activities and the logistic of that day.
        </p>

        <p>
          Another discussion brought up was the issue of members failing to attend meetings without permission. Chairman asked if he should send those involved a query letter regarding absenteeism. VC suggested that we should follow our constitution. Members should either abide by it or risk losing their membership. Ugo and Francis supported the suggestion. Regarding Eloka's membership, chairman promised to take the necessary actions before the next meeting.
        </p>

        <h5>Any Other Business</h5>
        <p>
          it was agreed that members should be available for activities such as Tidy Town and other related activities in their local area. Also, during the meeting, Mrs Obi visited, accompanied by her son and Madam Florence. She presented drinks to the association as a token of appreciation for the efforts and sacrifices made in ensuring that the repatriation and funeral of her late husband was successfully achieved. Members praised her bravery and thanked her for the noble visit, recognising that the visitation is an important step that many others would have overlooked
        </p>
        <h5>Finance</h5>
        <p>
          The sum of €50 + £10 was collected for the dues. €10 each was given to Ugo and Chuks as part of their payment. The motion for adjournment was moved by francis, seconded by Emeka at 8:00 pm.
          The next meeting is scheduled for May 8, 2022, and will be hosted by Ugo
          The closing prayer was said by Francis
        </p>
      </main>
    </div>
  );
}

export default April2022Meeting;


