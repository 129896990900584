
import React from 'react';
import { MDBCheckbox } from 'mdb-react-ui-kit';
import Navbars from '../../MinutesPage/Navbars'
import { Container, Navbar, Col, Row } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';
import styled from 'styled-components'


function Update() {
  return (
    <div className="Article-cover">
      {<Navbars />}

      {/*    page body starts here */}

      <MAIN className='main'>

        <div className='relative top-5 update-title'>
          <div className="row" style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
            <div className="col-md-12 text-dark">
              <h5 className='d-flex justify-content-center align-items-center relative'>
                <caption className='captions' style={{ minWidth: '50%', borderBottom: '3px solid gray' }}>
                  <strong className='w-100 relative left-3'>
                    Year 2022 | Financial Record and Contribution Report</strong></caption>
              </h5>
            </div>
          </div>
        </div>

        <div className='relative top-22 mt-12'>

          <div className='tab update-table'>

            <Table striped bordered hover variant="light">

              <tbody>
                <tr>
                  <td colSpan={5}><strong>Names</strong></td>
                  <td><strong>Amount</strong></td>
                  <td><strong>Status</strong></td>
                </tr>

                <tr className='mt-2'>
                  <td className='no-border' colSpan={5}>
                    Francis Udoku
                  </td>
                  <td>€100</td>
                  <td>
                    <MDBCheckbox name='flexCheck' className='relative left-4' id='flexCheckChecked' defaultChecked />
                  </td>
                </tr>
                <tr>
                  <td className='no-border' colSpan={5}>Emeka Onwuzo</td>
                  <td>€100</td>
                  <td>
                    <MDBCheckbox name='flexCheck' className='relative left-4' id='flexCheckChecked' defaultChecked />
                  </td>
                </tr>
                <tr>
                  <td className='no-border' colSpan={5}>Kingsley Agoha</td>
                  <td>€100</td>
                  <td>
                    <MDBCheckbox name='flexCheck' className='relative left-4' id='flexCheckChecked' defaultChecked />
                  </td>
                </tr>
                <tr>
                  <td className='no-border' colSpan={5}>
                    Charles Obodoechina
                  </td>

                  <td>€100</td>
                  <td>
                    <MDBCheckbox name='flexCheck' className='relative left-4' id='flexCheckChecked' defaultChecked />
                  </td>
                </tr>
                <tr>
                  <td className='no-border' colSpan={5}>
                    Chijioke Ogujuwa
                  </td>
                  <td>€100</td>
                  <td>
                    <MDBCheckbox name='flexCheck' className='relative left-4' id='flexCheckChecked' defaultChecked />
                  </td>
                </tr>
                <tr>
                  <td className='no-border' colSpan={5}>Obinna</td>
                  <td>€100</td>
                  <td>
                    <MDBCheckbox name='flexCheck' className='relative left-4' id='flexCheckChecked' defaultChecked />
                  </td>
                </tr>

                <tr>
                  <td className='no-border' colSpan={5}>Okezie Emmanuel</td>
                  <td>€100</td>
                  <td>
                    <MDBCheckbox name='flexCheck' className='relative left-4' id='flexCheckChecked' defaultChecked />
                  </td>
                </tr>
                <tr>
                  <td className='no-border' colSpan={5}>Soluchi Nwaosu</td>
                  <td>€100</td>
                  <td>
                    <MDBCheckbox name='flexCheck' className='relative left-4' id='flexCheckChecked' defaultChecked />
                  </td>
                </tr>
                <tr>
                  <td className='no-border' colSpan={5}>Ugo Nwankwo</td>
                  <td>€100</td>
                  <td>
                    <MDBCheckbox name='flexCheck' className='relative left-4' id='flexCheckChecked' defaultChecked />
                  </td>
                </tr>
                <tr>
                  <td className='no-border' colSpan={5} >Jerry Obi Jnr</td>
                  <td>€100</td>
                  <td>
                    <MDBCheckbox name='flexCheck' className='relative left-4' id='flexCheckChecked' defaultChecked />
                  </td>
                </tr>

                <tr>
                  <td className='no-border mt-3' colSpan={5}><strong className='relative top-1'>Total Contribution</strong></td>
                  <td colSpan={2} ><strong className='relative top-1'>€1000</strong></td>
                </tr>

                <tr>
                  <td className='no-border donation-table ' colSpan={7}>
                    <strong className='text-danger donation-text-space'>CASH DONATION</strong></td>
                </tr>
                <tr>
                  <td className='no-border donation-table ' colSpan={7}>
                    <strong className='text-danger donation-text'>CASH DONATION</strong></td>
                </tr>
                <tr>
                  <td className='no-border' colSpan={5}>
                    Edozie
                  </td>
                  <td>€100</td>
                  <td>
                    <MDBCheckbox name='flexCheck' className='relative left-4' id='flexCheckChecked' defaultChecked />
                  </td>
                </tr>

                <tr>
                  <td className='no-border' colSpan={5}>
                    Ugo Nwankwo
                  </td>
                  <td>€250</td>
                  <td>
                    <MDBCheckbox name='flexCheck' className='relative left-4' id='flexCheckChecked' defaultChecked />
                  </td>
                </tr>

                <tr>
                  <td className='no-border' colSpan={5}>
                    Nwadike
                  </td>
                  <td>€120</td>
                  <td>
                    <MDBCheckbox name='flexCheck' className='relative left-4' id='flexCheckChecked' defaultChecked />
                  </td>
                </tr>

                <tr>
                  <td className='no-border' colSpan={5}>
                    Aloys
                  </td>
                  <td>€30</td>
                  <td>
                    <MDBCheckbox name='flexCheck' className='relative left-4' id='flexCheckChecked' defaultChecked />
                  </td>
                </tr>

                <tr>
                  <td className='no-border' colSpan={5}>
                    Obidi from Derry
                  </td>
                  <td>£60 = €67.51</td>
                  <td>
                    <MDBCheckbox name='flexCheck' className='relative left-4' id='flexCheckChecked' defaultChecked />
                  </td>
                </tr>

                <tr>
                  <td className='no-border' colSpan={5}>
                    Hilary
                  </td>
                  <td>€40</td>
                  <td>
                    <MDBCheckbox name='flexCheck' className='relative left-4' id='flexCheckChecked' defaultChecked />
                  </td>
                </tr>

                <tr>
                  <td className='no-border' colSpan={5}>
                    Obinna
                  </td>
                  <td>€100</td>
                  <td>
                    <MDBCheckbox name='flexCheck' className='relative left-4' id='flexCheckChecked' defaultChecked />
                  </td>
                </tr>

                <tr>
                  <td className='no-border mt-3' colSpan={5}><strong className='relative top-1'>Total Donation</strong></td>
                  <td colSpan={2} ><strong className='relative top-1'>€707.51</strong></td>
                </tr>

                <tr>
                  <td className='no-border donation-table ' colSpan={7}>
                    <strong className='text-danger donation-text-space'>ITEM DONATION</strong></td>
                </tr>
                <tr>
                  <td className='no-border donation-table ' colSpan={7}>
                    <strong className='text-danger donation-text'>ITEM DONATION</strong></td>
                </tr>



                <tr>
                  <td className='no-border' >Emeka Onwuzo</td>
                  <td colSpan={5}>2 litres of Scottish whisky</td>
                  <td>
                    <MDBCheckbox name='flexCheck' className='relative left-4' id='flexCheckChecked' defaultChecked />
                  </td>
                </tr>

                <tr>
                  <td className='no-border' >Emmanuel Okezie</td>
                  <td colSpan={5}> 3 bottles of James </td>
                  <td>
                    <MDBCheckbox name='flexCheck' className='relative left-4' id='flexCheckChecked' defaultChecked />
                  </td>
                </tr>

                <tr>
                  <td className='no-border' >Chidi Usanga</td>
                  <td colSpan={5}>1 bottle of Jagamaster</td>
                  <td>
                    <MDBCheckbox name='flexCheck' className='relative left-4' id='flexCheckChecked' defaultChecked />
                  </td>
                </tr>

                <tr>
                  <td className='no-border' >Chidiebere and Ike Innocent </td>
                  <td colSpan={5}>1 cartoon of Nigeria Guinness</td>
                  <td>
                    <MDBCheckbox name='flexCheck' className='relative left-4' id='flexCheckChecked' defaultChecked />
                  </td>
                </tr>

                <tr>
                  <td className='no-border'>charles Obodoechina </td>
                  <td colSpan={5}>1 cartoon of Nigeria Guinness + 1 litre Jack Daniels and 5 cartoons of water</td>
                  <td>
                    <MDBCheckbox name='flexCheck' className='relative left-4' id='flexCheckChecked' defaultChecked />
                  </td>
                </tr>

                <tr>
                  <td className='no-border' >Kingsley </td>
                  <td colSpan={5}>2 bottles of Hennessey </td>
                  <td>
                    <MDBCheckbox name='flexCheck' className='relative left-4' id='flexCheckChecked' defaultChecked />
                  </td>
                </tr>
                <tr>
                  <td className='no-border'>Francis </td>
                  <td colSpan={5}>Pay for Venue </td>
                  <td>
                    <MDBCheckbox name='flexCheck' className='relative left-4' id='flexCheckChecked' defaultChecked />
                  </td>
                </tr>


                <tr>
                  <td className='no-border donation-table ' colSpan={7}>
                    <strong className=' donation-text'>
                      <span style={{ borderBottom: '1px solid #000' }}>ITEMS LEFT AFTER PARTY</span></strong>
                    1 litre jameson <br />
                    75cl Jameson<br />
                    I litre jack Daniels<br />
                    I bottle jagermelfter<br />
                    10 bottles Nigerian Guinness
                  </td>
                </tr>
                <tr>
                  <td className='no-border donation-table ' colSpan={7}>
                    <strong className='text-danger donation-text-space'>CASH DONATION</strong></td>
                </tr>
                <tr>
                  <td className='no-border donation-table ' colSpan={7}>
                    <strong className='text-danger donation-text'>EXPENSES</strong></td>
                </tr>


                <tr>
                  <td className='no-border pl-3 ' colSpan={7}>
                    <span className=''>
                      Food = €835<br />
                      Portrait: €200<br />
                      Projector: €150<br />
                      media: €100<br />
                      Flower: €14<br />
                      Decoration:  €50<br />
                      Kids drinks, Malt Confectionery: = €71.20<br />
                      Wine: = €96.09<br />
                      Belfast Travel: = €100<br />
                      {'----------------------------------------'}<br />

                      <span>
                        <strong className='pr-3'>Total Expenses:</strong> €1,616.29
                      </span><br /><br />
                      {'-----------------------------------------'}<br />
                      <p>
                        <strong>Total Cash Realised Less Expenses:</strong>
                        <br /><span className='ml-5'>€1000 <i className='pl-4'>{'->'}Contribution</i></span><br />
                        <strong className='relative bottom-3'>{'+'}</strong>
                        <span className='ml-2'>€707.51<i className='pl-4'>{'->'}Donation</i></span>
                      </p>
                      <span className='relative bottom-6'>{'_____________________________'}</span>
                      <span className='m-2 relative right-44'>€1,707.51</span><br />
                      <strong className='relative bottom-4 left-2'>{'_'}</strong>
                      <span className='ml-2 pl-2'>€1,616.29<i className='pl-4'>{'->'}Expenses</i></span><br />
                      <span className='relative bottom-5'>{'_______________________________'}</span><br />
                      <span className='relative bottom-4 left-3'><strong className='pr-3'>Bal:</strong>€91.22</span>

                    </span>
                  </td>
                </tr>
              </tbody>

            </Table>
          </div>
        </div>

      </MAIN>
    </div>
  )
}

export default Update

const MAIN = styled.main`
  display: flex !important;
  flex-direction:column !important;
  justify-content:center !important;
  align-items:center !important;
  .update-table{
  min-width:600px;
  max-width:600px;
  @media(max-width:992px){
  min-width:auto !important;
  max-width:auto !important;
  border:1px solid #333 !important;
 
}
  }
`




















